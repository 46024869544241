import { RootState } from 'app'

export const getHealthPlanReportsList = (state: RootState) =>
  state.HealthPlanReports.healthPlanReportsList

export const getReportsLoading = (state: RootState) =>
  state.HealthPlanReports.healthPlanReportsListLoading

export const getReportsFetched = (state: RootState) =>
  state.HealthPlanReports.healthPlanReportsListFetched
