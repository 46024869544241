import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { MODULE_NAME } from '../strings'
import {
  AccountRequestListItem,
  AddNewUserPayload,
  AddUserOrgPayload,
  AdminRequestPayload,
  AdminUsersRequestPayload,
  EditAdminUserPayload,
  GetAdminUserByUserNamePayload,
  GetOrgListPayload,
} from '../types/data'
import AdminPanelApi from './services'

const api = new AdminPanelApi()

export const getAuthorizations = createAsyncThunk(
  `${MODULE_NAME}/authorizations`,
  async (payload: AdminRequestPayload) => api.getListOfAuthorizations(payload)
)

export const approveAuthorizationRequest = createAsyncThunk(
  `${MODULE_NAME}/approve-request`,
  async (payload: AccountRequestListItem) =>
    api.approveAuthorizationRequest(payload)
)

export const denyAuthorizationRequest = createAsyncThunk(
  `${MODULE_NAME}/deny-request`,
  async (payload: AccountRequestListItem) =>
    api.denyAuthorizationRequest(payload)
)

export const toggleShowPastRequests = createAction<boolean>(
  `${MODULE_NAME}/toggleShowPastRequests`
)

export const getAdminUsers = createAsyncThunk(
  `${MODULE_NAME}/users`,
  async (payload: AdminUsersRequestPayload) =>
    api.getUsersAndRelatedOrganizations(payload)
)

export const resetAdminUsersList = createAction(
  `${MODULE_NAME}/resetAdminUsersList`
)

export const getAdminUserByUserName = createAsyncThunk(
  `${MODULE_NAME}/user-by-username`,
  async (payload: GetAdminUserByUserNamePayload) =>
    api.getUserByUserName(payload)
)

export const editAdminUser = createAsyncThunk(
  `${MODULE_NAME}/edit-user`,
  async (payload: EditAdminUserPayload, { rejectWithValue }) => {
    try {
      await api.editAdminUser(payload)
    } catch (error) {
      return rejectWithValue({
        message: (error as any).response.data.Message,
        status: (error as any).response.status,
        error,
      })
    }
  }
)

export const getListOfUserOrganizations = createAsyncThunk(
  `${MODULE_NAME}/organizations`,
  async (payload: GetOrgListPayload) => api.getListOfUserOrganizations(payload)
)

export const addNewOrg = createAsyncThunk(
  `${MODULE_NAME}/add-org`,
  async (payload: AddUserOrgPayload) => api.addNewOrg(payload)
)

export const deleteUserOrg = createAsyncThunk(
  `${MODULE_NAME}/delete-org`,
  async (payload: AddUserOrgPayload) => api.deleteUserOrg(payload)
)

export const getAdminSupervisors = createAsyncThunk(
  `${MODULE_NAME}/admin-supervisors`,
  async () => api.getAdminSupervisors()
)

export const addNewUser = createAsyncThunk(
  `${MODULE_NAME}/add-user`,
  async (payload: AddNewUserPayload, { rejectWithValue }) => {
    try {
      await api.addNewUser(payload)
    } catch (error) {
      return rejectWithValue({
        message: (error as any).response.data.Message,
        status: (error as any).response.status,
        error,
      })
    }
  }
)

export const resetEditUserRejectPayload = createAction<string>(
  `${MODULE_NAME}/resetEditUserPayload`
)

export const getListOfHealthPlanCodes = createAsyncThunk(
  `${MODULE_NAME}/healthPlanCodes`,
  async () => api.getListOfHealthPlanCodes()
)

export const setUserAdded = createAction<boolean>(`${MODULE_NAME}/userAdded`)

export const resendVerificationEmail = createAsyncThunk(
  `${MODULE_NAME}/resendVerificationEmail`,
  async (userId: number) => api.resendVerificationEmail(userId)
)

export const importUsers = createAsyncThunk(
  `${MODULE_NAME}/importUsers`,
  async (file: File) => api.importUsers(file)
)

export const resetUsersImportedStatus = createAction(
  `${MODULE_NAME}/resetUsersImportedStatus`
)

export const fetchSurveyAnalytics = createAsyncThunk(
  `${MODULE_NAME}/surveyAnalytics`,
  async (days: number) => api.fetchAdminAnalytics(days)
)

export const syncUsersWithAzure = createAsyncThunk(
  `${MODULE_NAME}/syncUsersWithAzure`,
  async () => api.azureUserSync()
)
