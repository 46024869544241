import { StrictMode, Suspense } from 'react'
import ReactDOM from 'react-dom'
import { i18n } from 'config'
import { Loader } from 'shared/components'
import App from './app'
import reportWebVitals from './reportWebVitals'
import './shared/fonts/index'

i18n.init()

ReactDOM.render(
  <StrictMode>
    <Suspense fallback={<Loader />}>
      <App />
    </Suspense>
  </StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
