import { createReducer } from '@reduxjs/toolkit'
import { LoadingStatus } from 'shared/types'
import { ProviderListPayload, ProviderDetails } from '../types/data'
import {
  fetchProvidersFilters,
  fetchProvidersList,
  fetchProviderDetails,
  removeProviderDetails,
  fetchAssociatedProvidersList,
  fetchReferringProvidersList,
  resetProvidersList,
  fetchProvidersListReferring,
} from './actions'
import {
  AssociatedProviderListPayload,
  ReferringProviderItem,
} from '../../Patients/components/AuthorizationRequestsTab/ReferringProvider/ReferringProvider.utils'

interface State {
  something: string
  loading: LoadingStatus
  error?: string | null
  providersList: ProviderListPayload
  associatedProvidersList: AssociatedProviderListPayload
  referringProvidersList: ReferringProviderItem[]
  providerDetailsList: ProviderDetails[]
  loadingData: boolean
  isDataFetched: boolean
  subTableLoading: {
    loadingData: boolean
    isDataFetched: boolean
  }
  providersFilters: {
    specialties: string[]
    counties: string[]
    cities: { cityName: string; countyName: string[] }[]
  }
}

const initialState: State = {
  something: '',
  loading: LoadingStatus.Idle,
  error: null,
  providersList: {
    currentPage: 0,
    pageSize: 0,
    sortingColumnName: null,
    results: [],
    totalResults: 0,
  },
  associatedProvidersList: {
    currentPage: 0,
    pageSize: 0,
    sortingColumnName: null,
    results: [],
    totalResults: 0,
  },
  referringProvidersList: [],
  providerDetailsList: [],
  loadingData: true,
  isDataFetched: false,
  subTableLoading: {
    loadingData: true,
    isDataFetched: false,
  },
  providersFilters: {
    specialties: [],
    counties: [],
    cities: [],
  },
}

export default createReducer(initialState, builder =>
  builder
    .addCase(fetchProvidersFilters.fulfilled, (state, action) => {
      state.providersFilters = action.payload
    })
    .addCase(fetchProvidersList.pending, (state, action) => {
      state.loadingData = true
      state.isDataFetched = false
    })
    .addCase(fetchProvidersList.fulfilled, (state, action) => {
      state.loadingData = false
      state.providersList = action.payload
      state.isDataFetched = true
    })
    .addCase(fetchProvidersList.rejected, (state, action) => {
      state.loadingData = false
      state.isDataFetched = false
    })
    .addCase(resetProvidersList, state => {
      state.providersList = {
        currentPage: 0,
        pageSize: 0,
        sortingColumnName: null,
        results: [],
        totalResults: 0,
      }
    })

    .addCase(fetchProvidersListReferring.pending, (state, action) => {
      state.loadingData = true
      state.isDataFetched = false
    })
    .addCase(fetchProvidersListReferring.fulfilled, (state, action) => {
      state.loadingData = false
      state.providersList = action.payload
      state.isDataFetched = true
    })
    .addCase(fetchProvidersListReferring.rejected, (state, action) => {
      state.loadingData = false
      state.isDataFetched = false
    })

    .addCase(fetchProviderDetails.pending, (state, action) => {
      state.subTableLoading.loadingData = true
      state.subTableLoading.isDataFetched = false
    })
    .addCase(fetchProviderDetails.fulfilled, (state, action) => {
      state.subTableLoading.loadingData = false
      state.providerDetailsList.push(action.payload)
      state.subTableLoading.isDataFetched = true
    })
    .addCase(fetchProviderDetails.rejected, (state, action) => {
      state.subTableLoading.loadingData = false
      state.subTableLoading.isDataFetched = false
    })
    .addCase(removeProviderDetails, (state, action) => {
      state.providerDetailsList = state.providerDetailsList.filter(
        element => element.id !== action.payload
      )
    })
    .addCase(fetchAssociatedProvidersList.pending, (state, action) => {
      state.loadingData = true
      state.isDataFetched = false
    })
    .addCase(fetchAssociatedProvidersList.fulfilled, (state, action) => {
      state.loadingData = false
      state.associatedProvidersList = action.payload
      state.isDataFetched = true
    })
    .addCase(fetchAssociatedProvidersList.rejected, (state, action) => {
      state.loadingData = false
      state.isDataFetched = false
    })

    .addCase(fetchReferringProvidersList.pending, (state, action) => {
      state.loadingData = true
      state.isDataFetched = false
    })
    .addCase(fetchReferringProvidersList.fulfilled, (state, action) => {
      state.referringProvidersList = action.payload
    })
    .addCase(fetchReferringProvidersList.rejected, (state, action) => {
      state.loadingData = false
      state.isDataFetched = false
    })
)
