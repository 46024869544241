import { createReducer, SerializedError } from '@reduxjs/toolkit'
import {
  addNewOrg,
  addNewUser,
  approveAuthorizationRequest,
  deleteUserOrg,
  denyAuthorizationRequest,
  editAdminUser,
  fetchSurveyAnalytics,
  getAdminSupervisors,
  getAdminUserByUserName,
  getAdminUsers,
  getAuthorizations,
  getListOfHealthPlanCodes,
  getListOfUserOrganizations,
  importUsers,
  resendVerificationEmail,
  resetAdminUsersList,
  resetEditUserRejectPayload,
  resetUsersImportedStatus,
  setUserAdded,
} from './actions'
import {
  AccountRequestListItemPayload,
  ExpandedAdminUserPayload,
  GetHealthPlanCodesPayload,
  ImportUserPayload,
  SupervisorDetails,
  SurveyAnalyticsPayload,
  UserOrganizationsPayload,
  UsersListItemPayload,
} from '../types/data'
import { UserPayload } from '../components/AdminTabs/AdminUsersTab/AdminUsersTable/AdminUsersSubTable/UsersDetailedInfoTable/UsersDetailedInnfoTable.utils'

interface State {
  adminAuthRequestsList: AccountRequestListItemPayload
  adminAuthRequestsFetched: boolean
  adminAuthRequestsLoading: boolean
  approveOrDenyRequestLoading: boolean
  approveOrDenyRequestFetched: boolean
  adminUsersAndOrganizations: UsersListItemPayload
  adminUsersFetched: boolean
  adminUsersLoading: boolean
  adminUserEditLoadingStatus: boolean
  adminUserEditFetchedStatus: boolean
  editedUserInfo: UserPayload
  editedUserInfoLoading: boolean
  editedUserInfoFetched: boolean
  expandedAdminUser: ExpandedAdminUserPayload
  expandedUserDataLoading: boolean
  expandedUserDataFetched: boolean
  listOfUserOrganizations: UserOrganizationsPayload
  listOfUserOrganizationsLoading: boolean
  organizationActionFetched: boolean
  listOfAdminSupervisors: SupervisorDetails[]
  listOfAdminSupervisorsLoading: boolean
  addNewUserResponsePayload: string
  addNewUserFetched: boolean
  addNewUserLoading: boolean
  editUserRejectPayload: string
  healthPlanCodesList: GetHealthPlanCodesPayload[]
  verificationEmailResentLoading: boolean
  verificationEmailResentRejected: boolean
  importUsersResult: ImportUserPayload
  importUsersResultLoading: boolean
  importUsersResultFetched: boolean
  surveyAnalytics: SurveyAnalyticsPayload
  surveyAnalyticsLoading: boolean
}

const initialState: State = {
  adminAuthRequestsList: {
    currentPage: 0,
    pageSize: 10,
    sortingColumnName: 'firstName',
    results: [
      {
        id: 0,
        showPast: false,
        acceptanceStatus: '',
        dateRequested: '',
        email: '',
        firstName: '',
        lastName: '',
        taxIdOrNationalProviderId: '',
        sortingColumnName: '',
        phone: '',
        fax: '',
        userName: '',
        supervisorFullName: '',
        supervisorPhone: '',
        supervisorEmail: '',
        remindersCount: 0,
        lastReminderSentAt: '',
        rowIndex: 0,
        cozeva: 0,
        processedAt: '',
      },
    ],
    totalResults: 0,
  },
  adminAuthRequestsFetched: false,
  adminAuthRequestsLoading: false,
  approveOrDenyRequestLoading: false,
  approveOrDenyRequestFetched: false,
  adminUsersAndOrganizations: {
    currentPage: 0,
    pageSize: 10,
    sortingColumnName: 'firstName',
    results: [
      {
        id: 0,
        lastName: '',
        firstName: '',
        email: '',
        taxIdOrNationalProviderId: '',
        active: 0,
        lastActive: '',
        securityLevel: '',
        userName: '',
      },
    ],
    totalResults: 0,
  },
  adminUsersFetched: false,
  adminUsersLoading: false,
  adminUserEditLoadingStatus: false,
  adminUserEditFetchedStatus: false,
  editedUserInfo: {
    id: 0,
    lastName: '',
    firstName: '',
    email: '',
    taxIdOrNationalProviderId: '',
    active: 0,
    lastLogin: '',
    accessLevel: '',
    userName: '',
  },
  editedUserInfoLoading: false,
  editedUserInfoFetched: false,
  expandedAdminUser: {
    id: 0,
    lastName: '',
    firstName: '',
    email: '',
    sortingColumnName: '',
    taxIdOrNationalProviderId: '',
    phone: '',
    userName: '',
    fax: '',
    supervisorFullName: '',
    supervisorPhone: '',
    supervisorEmail: '',
    page: 0,
    pageSize: 0,
    orderBy: '',
    securityLevel: '',
    lastLogin: '',
    active: 0,
    userOrganizations: [],
    approved: '',
    approvedBy: '',
    modified: '',
    modifiedBy: '',
    lastActive: '',
    loginCount: 0,
    cozeva: 0,
    isAdmin: 0,
    verified: 0,
  },
  expandedUserDataLoading: false,
  expandedUserDataFetched: false,
  listOfUserOrganizations: {
    currentPage: 0,
    pageSize: 10,
    sortingColumnName: '',
    results: [
      {
        organizationShortId: '',
        orgName: '',
        taxId: '',
      },
    ],
    totalResults: 0,
  },
  listOfUserOrganizationsLoading: false,
  organizationActionFetched: false,
  listOfAdminSupervisors: [
    {
      fullName: '',
    },
  ],
  listOfAdminSupervisorsLoading: false,
  addNewUserResponsePayload: '',
  addNewUserFetched: false,
  editUserRejectPayload: '',
  healthPlanCodesList: [
    {
      healthPlanCode: '',
      healthPlanCodeAndName: '',
    },
  ],
  addNewUserLoading: false,
  verificationEmailResentLoading: false,
  verificationEmailResentRejected: false,
  importUsersResult: {
    migratedUsers: [
      {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
      },
    ],
    usersAlreadyExistEmails: [],
    error: '',
  },
  importUsersResultLoading: false,
  importUsersResultFetched: false,
  surveyAnalytics: {
    experience: [
      {
        rating: 0,
        numOfUsers: 0,
      },
    ],
    easyToUse: [
      {
        rating: 0,
        numOfUsers: 0,
      },
    ],
    expectations: [
      {
        rating: 0,
        numOfUsers: 0,
      },
    ],
    easyToFindInfo: [
      {
        rating: 0,
        numOfUsers: 0,
      },
    ],
    proposals: [
      {
        proposalText: '',
        createdDate: '',
      },
    ],
    feedbacksCount: 0,
  },
  surveyAnalyticsLoading: false,
}

export default createReducer(initialState, builder => {
  builder
    .addCase(getAuthorizations.fulfilled, (state, action) => {
      state.adminAuthRequestsList = action.payload
      state.adminAuthRequestsLoading = false
      state.adminAuthRequestsFetched = true
      state.approveOrDenyRequestFetched = false
    })
    .addCase(getAuthorizations.pending, state => {
      state.adminAuthRequestsFetched = false
      state.adminAuthRequestsLoading = true
    })
    .addCase(getAuthorizations.rejected, state => {
      state.adminAuthRequestsFetched = false
      state.adminAuthRequestsLoading = false
    })
    .addCase(approveAuthorizationRequest.pending, state => {
      state.approveOrDenyRequestLoading = true
      state.approveOrDenyRequestFetched = false
    })
    .addCase(approveAuthorizationRequest.fulfilled, state => {
      state.approveOrDenyRequestLoading = false
      state.approveOrDenyRequestFetched = true
    })
    .addCase(approveAuthorizationRequest.rejected, state => {
      state.approveOrDenyRequestLoading = false
      state.approveOrDenyRequestFetched = false
    })
    .addCase(denyAuthorizationRequest.pending, state => {
      state.approveOrDenyRequestLoading = true
      state.approveOrDenyRequestFetched = false
    })
    .addCase(denyAuthorizationRequest.fulfilled, state => {
      state.approveOrDenyRequestLoading = false
      state.approveOrDenyRequestFetched = true
    })
    .addCase(denyAuthorizationRequest.rejected, state => {
      state.approveOrDenyRequestLoading = false
      state.approveOrDenyRequestFetched = false
    })

    // fetch admin orgs for Organizations autocomplete
    .addCase(getAdminUsers.fulfilled, (state, action) => {
      state.adminUsersAndOrganizations = action.payload
      state.adminUsersLoading = false
      state.adminUsersFetched = true
    })
    .addCase(getAdminUsers.pending, state => {
      state.adminUsersLoading = true
      state.adminUsersFetched = false
    })
    .addCase(getAdminUsers.rejected, state => {
      state.adminUsersLoading = false
      state.adminUsersFetched = false
    })

    // reset admin orgs for Organizations autocomplete
    .addCase(resetAdminUsersList, state => {
      state.listOfUserOrganizations = {
        currentPage: 0,
        pageSize: 10,
        sortingColumnName: '',
        results: [
          {
            organizationShortId: '',
            orgName: '',
            taxId: '',
          },
        ],
        totalResults: 0,
      }
    })

    .addCase(editAdminUser.fulfilled, (state, action: any) => {
      state.adminUserEditLoadingStatus = false
      state.adminUserEditFetchedStatus = true
      state.editUserRejectPayload = 'success'
    })
    .addCase(editAdminUser.pending, state => {
      state.adminUserEditLoadingStatus = true
      state.adminUserEditFetchedStatus = false
      state.editUserRejectPayload = ''
    })
    .addCase(editAdminUser.rejected, (state, action: any) => {
      state.adminUserEditLoadingStatus = false
      state.adminUserEditFetchedStatus = true //// TODO check here
      state.editUserRejectPayload = action.payload.message
    })
    .addCase(resetEditUserRejectPayload, state => {
      state.editUserRejectPayload = ''
    })
    .addCase(getAdminUserByUserName.fulfilled, (state, action) => {
      state.expandedAdminUser = action.payload
      state.expandedUserDataFetched = true
      state.expandedUserDataLoading = false
    })
    .addCase(getAdminUserByUserName.pending, state => {
      state.expandedUserDataFetched = false
      state.expandedUserDataLoading = true
    })
    .addCase(getAdminUserByUserName.rejected, state => {
      state.expandedUserDataFetched = false
      state.expandedUserDataLoading = false
    })
    .addCase(getListOfUserOrganizations.fulfilled, (state, action) => {
      state.listOfUserOrganizations = action.payload
      state.listOfUserOrganizationsLoading = false
    })
    .addCase(getListOfUserOrganizations.pending, state => {
      state.listOfUserOrganizationsLoading = true
    })
    .addCase(getListOfUserOrganizations.rejected, state => {
      state.listOfUserOrganizationsLoading = false
    })
    .addCase(addNewOrg.fulfilled, state => {
      state.organizationActionFetched = true
    })
    .addCase(addNewOrg.pending, state => {
      state.organizationActionFetched = false
    })
    .addCase(addNewOrg.rejected, state => {
      state.organizationActionFetched = false
    })
    .addCase(deleteUserOrg.fulfilled, state => {
      state.organizationActionFetched = true
    })
    .addCase(deleteUserOrg.pending, state => {
      state.organizationActionFetched = false
    })
    .addCase(deleteUserOrg.rejected, state => {
      state.organizationActionFetched = false
    })
    .addCase(getAdminSupervisors.fulfilled, (state, action) => {
      state.listOfAdminSupervisors = action.payload
      state.listOfAdminSupervisorsLoading = false
    })
    .addCase(getAdminSupervisors.pending, state => {
      state.listOfAdminSupervisorsLoading = true
    })
    .addCase(getAdminSupervisors.rejected, state => {
      state.listOfAdminSupervisorsLoading = false
    })
    .addCase(addNewUser.rejected, (state, action: any) => {
      state.addNewUserResponsePayload = action.payload.message
      state.addNewUserFetched = false
      state.addNewUserLoading = false
    })
    .addCase(addNewUser.fulfilled, state => {
      state.addNewUserResponsePayload = ''
      state.addNewUserFetched = true
      state.addNewUserLoading = false
    })
    .addCase(addNewUser.pending, state => {
      state.addNewUserResponsePayload = ''
      state.addNewUserFetched = false
      state.addNewUserLoading = true
    })
    .addCase(getListOfHealthPlanCodes.fulfilled, (state, action) => {
      state.healthPlanCodesList = action.payload
    })
    .addCase(setUserAdded, state => {
      state.addNewUserFetched = false
    })
    .addCase(resendVerificationEmail.fulfilled, state => {
      state.verificationEmailResentLoading = false
      state.verificationEmailResentRejected = false
    })
    .addCase(resendVerificationEmail.pending, state => {
      state.verificationEmailResentLoading = true
      state.verificationEmailResentRejected = false
    })
    .addCase(resendVerificationEmail.rejected, state => {
      state.verificationEmailResentLoading = false
      state.verificationEmailResentRejected = true
    })

    // import users from admin panel
    .addCase(importUsers.fulfilled, (state, action) => {
      state.importUsersResult = action.payload
      state.importUsersResultLoading = false
      state.importUsersResultFetched = true
    })
    .addCase(importUsers.pending, state => {
      state.importUsersResultLoading = true
      state.importUsersResultFetched = false
    })
    .addCase(importUsers.rejected, state => {
      state.importUsersResultLoading = false
      state.importUsersResultFetched = false
    })
    .addCase(resetUsersImportedStatus, state => {
      state.importUsersResultFetched = false
      state.importUsersResult = {
        migratedUsers: [
          {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
          },
        ],
        usersAlreadyExistEmails: [],
        error: '',
      }
    })

    // SURVEY ANALYTICS
    .addCase(fetchSurveyAnalytics.fulfilled, (state, action) => {
      state.surveyAnalytics = action.payload
      state.surveyAnalyticsLoading = false
    })
    .addCase(fetchSurveyAnalytics.rejected, state => {
      state.surveyAnalyticsLoading = false
    })
    .addCase(fetchSurveyAnalytics.pending, state => {
      state.surveyAnalyticsLoading = true
    })
})
