import { createAsyncThunk } from '@reduxjs/toolkit'
import { MODULE_NAME } from '../strings'
import { EOBDetailsPayload, EOBListReqPayload } from '../types/data'
import EOBApi from './services'

const api = new EOBApi()

export const fetchEOBData = createAsyncThunk(
  `${MODULE_NAME}/eobData`,
  async (payload: EOBListReqPayload) => api.searchEOBList(payload)
)

export const fetchEOBListItemDetails = createAsyncThunk(
  `${MODULE_NAME}/eobListItem`,
  async (payload: EOBDetailsPayload[]) => api.getEOBItemDetails(payload)
)

export const fetchEOBOrgs = createAsyncThunk(
  `${MODULE_NAME}/eobOrgs`,
  async () => api.getEOBOrgs()
)
