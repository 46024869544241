const paths = {
  home: '/',
  login: '/login',
  registration: '/registration',
  authorizations: '/authorizations',
  provider: '/provider',
  reports: '/reports',
  raf: '/raf',
  assignments: '/assignments',
  resources: '/resources',
  request_accepted: '/request_accepted',
  request_deny: '/request_deny',
  request_denied: '/request_denied',
  request_link_expired: '/request_link_expired',
  request_sent_successfully: '/request_sent_successfully',
  request_sent_fail: '/request_sent_fail',
  set_password: '/set_password',
}

export default paths
