import { createReducer } from '@reduxjs/toolkit'
import { DCEListData } from '../types/data'
import {
  fetchDCEAccessCheck,
  fetchDCEProviders,
  fetchDCERosterData,
  resetDceProvidersList,
} from './actions'

interface State {
  dceRosterData: DCEListData
  dceRosterDataLoading: boolean
  dceRosterDataFetched: boolean
  dceProviderList: [{ fullName: string; lastName: string }] | []
  dceProviderListLoading: boolean
  dceCompatible: boolean
}

const initialState: State = {
  dceRosterData: {
    currentPage: 0,
    pageSize: 0,
    sortingColumnName: '',
    sortingDirection: 0,
    results: [
      {
        lastName: '',
        firstName: '',
        dateOfBirth: '',
        memberShortId: '',
        pcpLastName: '',
        pcpFirstName: '',
        healthPlanCode: '',
        healthPlanName: '',
        pcpEffectiveDate: '',
        phoneNumber: '',
        suffix: '',
      },
    ],
    totalResults: 0,
  },
  dceRosterDataLoading: false,
  dceRosterDataFetched: false,
  dceProviderList: [],
  dceCompatible: false,
  dceProviderListLoading: false,
}

export default createReducer(initialState, builder =>
  builder
    // DCE providers list
    .addCase(fetchDCERosterData.pending, state => {
      state.dceRosterDataLoading = true
      state.dceRosterDataFetched = false
    })
    .addCase(fetchDCERosterData.fulfilled, (state, action) => {
      state.dceRosterDataLoading = false
      state.dceRosterDataFetched = true
      state.dceRosterData = action.payload
    })
    .addCase(fetchDCERosterData.rejected, state => {
      state.dceRosterDataLoading = false
      state.dceRosterDataFetched = false
    })

    // DCE providers for a autocomplete dropdown
    .addCase(fetchDCEProviders.fulfilled, (state, action) => {
      state.dceProviderList = action.payload
      state.dceProviderListLoading = false
    })
    .addCase(fetchDCEProviders.rejected, state => {
      state.dceProviderListLoading = false
    })
    .addCase(fetchDCEProviders.pending, state => {
      state.dceProviderListLoading = true
    })

    // resetting list of DCE providers on autocomplete blur
    .addCase(resetDceProvidersList, state => {
      state.dceProviderList = []
    })

    // DCE access check
    .addCase(fetchDCEAccessCheck.fulfilled, (state, action) => {
      state.dceCompatible = action.payload
    })
)
