import { styled, makeStyles } from '@material-ui/core'
import { colors } from 'config'

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: '100%',
  '& > .MuiCircularProgress-root': {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
  },
}))

export const useLoaderStyles = makeStyles({
  color: {
    color: colors.decoration.blue,
  },
  buttonColor: {
    color: colors.decoration.tertiary,
  },
  pageLoader: {
    height: '100vh',
  },
  buttonLoader: {
    '& > .MuiCircularProgress-root': {
      marginTop: 0,
      marginBottom: 0,
    },
  },
})
