import { RootState } from 'app'

export const getAdminAuthRequestsList = (state: RootState) =>
  state.AdminPanel.adminAuthRequestsList

export const getAdminAuthRequestsLoadingStatus = (state: RootState) =>
  state.AdminPanel.adminAuthRequestsLoading

export const getAdminAuthRequestsFetchedStatus = (state: RootState) =>
  state.AdminPanel.adminAuthRequestsFetched

export const getAdminApproveDenyLoadingStatus = (state: RootState) =>
  state.AdminPanel.approveOrDenyRequestLoading

export const getAdminApproveDenyFetchedStatus = (state: RootState) =>
  state.AdminPanel.approveOrDenyRequestFetched

export const getAdminUsersAndOrganizations = (state: RootState) =>
  state.AdminPanel.adminUsersAndOrganizations

export const getAdminUsersLoadingStatus = (state: RootState) =>
  state.AdminPanel.adminUsersLoading

export const getAdminUsersFetchedStatus = (state: RootState) =>
  state.AdminPanel.adminUsersFetched

export const getEditAdminUsersFetchedStatus = (state: RootState) =>
  state.AdminPanel.adminUserEditFetchedStatus

export const getEditAdminUsersRejectPayload = (state: RootState) =>
  state.AdminPanel.editUserRejectPayload

export const getEditAdminUsersLoadingStatus = (state: RootState) =>
  state.AdminPanel.adminUserEditLoadingStatus

export const getUserDetails = (state: RootState) =>
  state.AdminPanel.editedUserInfo

export const getUserDetailsLoading = (state: RootState) =>
  state.AdminPanel.editedUserInfoLoading

export const getUserDetailsFetched = (state: RootState) =>
  state.AdminPanel.editedUserInfoFetched

export const getExpandedUserData = (state: RootState) =>
  state.AdminPanel.expandedAdminUser

export const getExpandedUserDataLoading = (state: RootState) =>
  state.AdminPanel.expandedUserDataLoading

export const getExpandedUserDataFetched = (state: RootState) =>
  state.AdminPanel.expandedUserDataFetched

export const getUserOrganizations = (state: RootState) =>
  state.AdminPanel.listOfUserOrganizations

export const getUserOrganizationsLoading = (state: RootState) =>
  state.AdminPanel.listOfUserOrganizationsLoading

export const getUserOrgStatus = (state: RootState) =>
  state.AdminPanel.organizationActionFetched

export const getListOfAdminSupervisors = (state: RootState) =>
  state.AdminPanel.listOfAdminSupervisors

export const getListOfAdminSupervisorsLoading = (state: RootState) =>
  state.AdminPanel.listOfAdminSupervisorsLoading

export const getAddNewUserRejectPayload = (state: RootState) =>
  state.AdminPanel.addNewUserResponsePayload

export const getAddNewUserFetched = (state: RootState) =>
  state.AdminPanel.addNewUserFetched

export const getAddNewUserLoading = (state: RootState) =>
  state.AdminPanel.addNewUserLoading

export const getListOfHealthPlans = (state: RootState) =>
  state.AdminPanel.healthPlanCodesList

export const getVerificationEmailResentLoading = (state: RootState) =>
  state.AdminPanel.verificationEmailResentLoading

export const getVerificationEmailResentRejected = (state: RootState) =>
  state.AdminPanel.verificationEmailResentRejected

export const getImportUsersLoadingStatus = (state: RootState) =>
  state.AdminPanel.importUsersResultLoading

export const getImportUsersFetchedStatus = (state: RootState) =>
  state.AdminPanel.importUsersResultFetched

export const getImportedUsersResults = (state: RootState) =>
  state.AdminPanel.importUsersResult

export const getSurveyAnalytics = (state: RootState) =>
  state.AdminPanel.surveyAnalytics

export const getSurveyAnalyticsLoading = (state: RootState) =>
  state.AdminPanel.surveyAnalyticsLoading
