import { RootState } from 'app'

export const getUserAccount = (state: RootState) => state.UserAccount
export const getUserInfo = (state: RootState) => state.UserAccount.userInfo
export const getLoadingStatus = (state: RootState) => state.UserAccount.loading
export const getDataLoadingStatus = (state: RootState) =>
  state.UserAccount.loadingData

export const getUserInfoStatus = (state: RootState) =>
  state.UserAccount.fetchLoading
export const getImageStatus = (state: RootState) =>
  state.UserAccount.imageLoading
export const getNewDataStatus = (state: RootState) =>
  state.UserAccount.saveDataLoading
export const getImageSavingProcessStatus = (state: RootState) =>
  state.UserAccount.modifyImageProcessLoading
