import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { MODULE_NAME } from '../strings'
import DCERosterApi from './services'

const api = new DCERosterApi()

export const fetchDCERosterData = createAsyncThunk(
  `${MODULE_NAME}/dceData`,
  async (payload: any) => api.getDCEPatientRosters(payload)
)

export const fetchDCEProviders = createAsyncThunk(
  `${MODULE_NAME}/dceProviders`,
  async (arg: string) => api.getDCEPatientProvidersList(arg)
)

export const fetchDCEAccessCheck = createAsyncThunk(
  `${MODULE_NAME}/dceAccessCheck`,
  async () => api.dceAccessCheck()
)

export const resetDceProvidersList = createAction(
  `${MODULE_NAME}/resetDceProvidersList`
)
