import { RootState } from 'app'

export const getDCEListData = (state: RootState) => state.DCE.dceRosterData
export const dceDataLoading = (state: RootState) =>
  state.DCE.dceRosterDataLoading
export const dceDataFetched = (state: RootState) =>
  state.DCE.dceRosterDataFetched

export const getDCEProviderList = (state: RootState) =>
  state.DCE.dceProviderList
export const getDCEProviderListLoading = (state: RootState) =>
  state.DCE.dceProviderListLoading

export const getDCECompatible = (state: RootState) => state.DCE.dceCompatible
