const colors = {
  background: {
    primary: '#ffffff',
    secondary: '#ECEFF3',
    tertiary: '#F9FDFF',
    lightBlue: '#E6F1F6',
    homePageComponent: '#DDE5F0',
    gradientFirst:
      'linear-gradient(161.31deg, rgba(0, 43, 95, 0.385) -1.13%, rgba(0, 43, 95, 0.055) 70.77%)',
    gradientSecond:
      'linear-gradient(142.25deg, rgba(255, 255, 255, 0.49) 1.05%, rgba(255, 255, 255, 0.07) 100%)',
  },
  imgGradient:
    'linear-gradient(90deg, rgba(0, 43, 95, 0.6) 49.71%, rgba(196, 216, 224, 0.44) 100%)',
  imgHomepageGradient:
    'linear-gradient(270.07deg, rgba(0, 43, 95, 0.4) 1.23%, rgba(0, 43, 95, 0.63) 99.54%);',

  table: {
    bgPrimary: '#FCFEFF',
    bgSecondary: '#F2F9FB',
  },
  navigation: {
    backgroud: '#F6F6F6',
    backgroundHover: '#007BB6',
    backgroundActive: '#002B5F',
    font: '#002B5F',
    fontHover: '#FFFFFF',
    fontActive: '#FFFFFF',
  },
  border: {
    primary: '#ffffff',
    root: '#1E65BA',
    grey: '#C4C4C4',
    white: '#ffffff',
  },
  font: {
    primary: '#1E65BA',
    black: '#35363A',
    lightGrey: '#827C7C',
    grey: '#343333',
    darkBlue: '#002B5F',
    orange: '#F27824',
    white: '#ffffff',
    red: '#E60303',
    blue: '#0685BD',
    lightBlue: '#BCE5F6',
    lightBlueHover: '#58C4F4',
    lightGreen: '#80BA00',
  },
  button: {
    root: '#1E65BA',
    hover: '#669BDC',
    grey: '#C4C4C4',
  },
  header: {
    gradient: {
      start: 'rgba(0, 43, 95, 0.6)',
      middle: 'rgba(0, 43, 95, 0.306)',
      end: 'rgba(0, 43, 95, 0.03)',
    },
  },
  footer: '#D2DCEB',
  login: {
    gradient: {
      start: 'rgba(0, 43, 95, 0.85)',
      middle: 'rgba(0, 43, 95, 0.306)',
      end: 'rgba(0, 43, 95, 0.1)',
    },
  },
  decoration: {
    primary: '#002B5F',
    secondary: '#F27824',
    tertiary: '#1E65BA',
    lightBlue: '#0685BD',
    blue: '#0685BD',
    whiteBlue: '#F4F7FC',
    white: '#FFFFFF',
    black: '#35363A',
    lightGrey: '#ECEFF3',
    mediumGrey: '#C4C4C4',
    darkGrey: '#B2B0B0',
    rowActiveBg: '#C4D8E0',
    lightBorder: '#C4D8E0',
    scrollBarColor: '#E9E8E8',
    scrollColor: '#BEBDBD',
    disable: '#E9E8E8',
    lightOrange: '#FFEDE0',
    green: '#80BA00',
    lightGreen: 'rgba(128, 186, 0, 0.19)',
    red: '#AC0909',
  },
  subTable: {
    headBackground: '#FFEDE0',
    rowBackground: '#FDFBFA',
    lightGrey: '#C4C4C4',
  },
  navActive: {
    background: 'rgba(30, 101, 186, 0.05)',
    border: '#002B5F',
    font: '#002B5F',
  },
  icons: {
    positive: '#80BA00',
    negative: '#AC0909',
  },
  changeImage: {
    blueBg: 'rgb(0, 43, 95, .7)',
    redBg: 'rgb(172, 9, 9, .5)',
  },
  success: '#289203',
  error: '#AC0909',
}

export default colors
