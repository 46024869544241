import { createReducer } from '@reduxjs/toolkit'
import { EOBListItemDetailsPayload, EOBListPayload } from '../types/data'
import { fetchEOBData, fetchEOBListItemDetails, fetchEOBOrgs } from './actions'

interface State {
  loadingEOBData: boolean
  isEOBDataFetched: boolean
  eobList: EOBListPayload
  EOBListItemDetails: EOBListItemDetailsPayload[] // TODO check here
  EOBListItemDetailsLoading: boolean
  EOBListItemDetailsFetched: boolean
  EOBOrgsList: string[]
}

const initialState: State = {
  loadingEOBData: false,
  isEOBDataFetched: false,
  eobList: {
    currentPage: 0,
    pageSize: 10,
    sortingColumnName: 'checkNo',
    results: [
      {
        checkNumber: 0,
        datePaid: '',
        organizationName: '',
        orgID: '',
      },
    ],
    totalResults: 0,
  },
  EOBListItemDetails: [
    {
      paidTotal: 0,
      allowTotal: 0,
      billedTotal: 0,
      checkNo: 0,
      datePaid: '',
      orgName: '',
      eobDetails: [
        {
          healthPlanName: '',
          billingProviders: [
            {
              providerFullName: '',
              primaryCareProviderListItem: [
                {
                  itemNo: 0,
                  memberID: '',
                  memberFullName: '',
                  accountNo: '',
                  memberClaims: [
                    {
                      claimNo: '',
                      memberClaims: [
                        {
                          dateOfService: '',
                          cptCode: '',
                          cptDescr: '',
                          qty: 0,
                          billed: 0,
                          allowed: 0,
                          copay: 0,
                          deductible: 0,
                          coinsurance: 0,
                          net: 0,
                          adjCodeDescr: '',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  EOBListItemDetailsLoading: false,
  EOBListItemDetailsFetched: false,
  EOBOrgsList: [''],
}

export default createReducer(initialState, builder =>
  builder
    .addCase(fetchEOBData.pending, state => {
      state.loadingEOBData = true
      state.isEOBDataFetched = false
    })
    .addCase(fetchEOBData.fulfilled, (state, action) => {
      state.loadingEOBData = false
      state.eobList = action.payload
      state.isEOBDataFetched = true
    })
    .addCase(fetchEOBData.rejected, state => {
      state.loadingEOBData = false
      state.isEOBDataFetched = false
    })
    .addCase(fetchEOBListItemDetails.fulfilled, (state, action) => {
      state.EOBListItemDetails = action.payload
      state.EOBListItemDetailsLoading = false
      state.EOBListItemDetailsFetched = true
    })
    .addCase(fetchEOBListItemDetails.rejected, state => {
      state.EOBListItemDetailsLoading = false
      state.EOBListItemDetailsFetched = false
    })
    .addCase(fetchEOBListItemDetails.pending, state => {
      state.EOBListItemDetailsLoading = true
      state.EOBListItemDetailsFetched = false
    })
    .addCase(fetchEOBOrgs.fulfilled, (state, action) => {
      state.EOBOrgsList = action.payload
    })
)
