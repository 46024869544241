import { Redirect, Route, RouteProps } from 'react-router-dom'
import { paths } from 'config'
import { useAuth } from 'shared/hooks'
import { getUserInfo } from 'modules/UserAccount/store/selectors'
import { useSelector } from 'react-redux'
import { HEALTH_PLAN_ACCESS, MMN } from 'shared/strings'
import MaintenanceBanner from './MaintenanceBanner'
// import NotificationBanner from './NotificationBanner/NotificationBanner'

const PrivateRoute = (props: RouteProps) => {
  const { isAuthenticated } = useAuth()
  const userInfo = useSelector(getUserInfo)
  const lineOfBusiness = window.localStorage.getItem('lineOfBusiness')

  if (userInfo.securityLevel) {
    localStorage.setItem('currentAccessLevel', userInfo.securityLevel)
    if (userInfo.securityLevel === HEALTH_PLAN_ACCESS)
      localStorage.setItem(
        'healthPlanCode',
        userInfo.healthPlanCodes.toString()
      )
  }

  return isAuthenticated ? (
    <>
      {/* <NotificationBanner /> */}
      <Route {...props} />
    </>
  ) : (
    <Redirect to={paths.login} />
  )
}

export default PrivateRoute
