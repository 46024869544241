import { createReducer } from '@reduxjs/toolkit'
import { ProviderMembersFilterItem, MembersListPayload } from '../types/data'
import {
  fetchProvidersMembersFilters,
  fetchMembersList,
  fetchPayorsMembersFilters,
} from './actions'

interface State {
  membersList: MembersListPayload
  loadingData: boolean
  isDataFetched: boolean
  membersFiltersPoviders: ProviderMembersFilterItem[]
  membersPayorsFilters: string[]
}

const initialState: State = {
  membersList: {
    currentPage: 0,
    pageSize: 0,
    sortingColumnName: null,
    results: [],
    totalResults: 0,
  },
  loadingData: true,
  isDataFetched: false,
  membersFiltersPoviders: [
    {
      providerLastName: '',
      providerFirstName: '',
    },
  ],
  membersPayorsFilters: [],
}

export default createReducer(initialState, builder =>
  builder
    .addCase(fetchProvidersMembersFilters.fulfilled, (state, action) => {
      const dataWithId = action.payload.map(
        (item: ProviderMembersFilterItem, index: number) => {
          item.temporaryId = index + 1
          return item
        }
      )
      state.membersFiltersPoviders = dataWithId
    })
    .addCase(fetchPayorsMembersFilters.fulfilled, (state, action) => {
      state.membersPayorsFilters = action.payload
    })
    .addCase(fetchMembersList.pending, state => {
      state.loadingData = true
      state.isDataFetched = false
    })
    .addCase(fetchMembersList.fulfilled, (state, action) => {
      state.loadingData = false
      state.membersList = action.payload
      state.isDataFetched = true
    })
    .addCase(fetchMembersList.rejected, (state, action) => {
      state.loadingData = false
      state.isDataFetched = false
    })
)
