import ReactGA from 'react-ga'
import * as ga4 from 'react-ga4'
import TagManager from 'react-gtm-module'

import { FCMG, HEALTH_PLAN_ACCESS, MMN } from 'shared/strings'

const tagManagerArgs = {
  gtmId: 'GTM-M5W453H',
}

const NBA_TRACKING_ID = process.env.NBA_GA4_TRACKING_ID || ''
const CV_TRACKING_ID = process.env.CV_GA4_TRACKING_ID || ''
const HP_TRACKING_ID = process.env.HP_GA4_TRACKING_ID || ''

const trackingId = process.env.REACT_APP_GA_TRACKING_ID || ''
const userAccount = window.localStorage.getItem('account')!
const userId = userAccount ? userAccount.match(/^(.*?)\-b2c/)![1] : undefined

const lineOfBusiness = window.localStorage.getItem('lineOfBusiness')
const accessLevel = window.localStorage.getItem('currentAccessLevel')

const usersToExcludeGA = [
  { userName: 'BFeeney', email: 'BFeeney@meritagemed.com' },
  { userName: 'btrief1', email: 'btrief@meritagemed.com' },
  { userName: 'erice1', email: 'erice@meritagemed.com' },
  { userName: 'hporter', email: 'hporter@meritagemed.com' },
  { userName: 'wnichols', email: 'wnichols@meritagemed.com' },
  { userName: 'rramos', email: 'rramos@meritagemed.com' },
  { userName: 'jconlin', email: 'jconlin@meritagemed.com' },
  { userName: 'Mfleetwood', email: 'mfleetwood@meritagemed.com' },
  { userName: 'KBartunek1', email: 'kbartunek@meritagemed.com' },
]

const initializeSelectedOrg = (id: string) => {
  ReactGA.initialize(trackingId)
  ReactGA.set({ userId })
  ga4.default.initialize(id)
  ga4.default.set({ userId })
}

const initializeTagManager = () => {
  TagManager.initialize(tagManagerArgs)
}

const initializeGA = () => {
  if (accessLevel === HEALTH_PLAN_ACCESS) initializeSelectedOrg('G-Z6RBT5HGV7')
  else
    switch (lineOfBusiness) {
      case MMN:
        initializeSelectedOrg('G-V22RXP718D')
        break

      case FCMG:
        initializeSelectedOrg('G-3R7NNTSVWZ')
        break

      default:
        break
    }
}

const initialize = () => {
  // initializeTagManager()
  initializeGA()
}

// for sending GA4 custom Login event
const sendGA4Login = (
  taxId: string,
  firstName: string,
  lastName: string,
  email: string,
  userName: string
) => {
  const fireLoginEvent = () =>
    ga4.default.gtag('event', 'login_event', {
      taxId,
      userId,
      firstName,
      lastName,
    })

  // skip firing the event for specific subset of users defined above
  if (
    usersToExcludeGA.some(
      excludeUser =>
        excludeUser.email === email && excludeUser.userName === userName
    )
  )
    return

  if (ga4.default.isInitialized) {
    fireLoginEvent()
  } else {
    // reinitialize and then fire event
    initializeGA()
    fireLoginEvent()
  }
}

// for sending GA4 custom events
const sendGAEvent = (
  eventSelector: string,
  taxId: string,
  firstName: string,
  lastName: string,
  email: string,
  userName: string
) => {
  // skipping firing the event for specific subset of users only
  if (
    usersToExcludeGA.some(
      excludeUser =>
        excludeUser.email === email && excludeUser.userName === userName
    )
  )
    return

  ga4.default.gtag('event', eventSelector, {
    taxId,
    userId,
    firstName,
    lastName,
  })
}

const setUserId = (id?: string) => ReactGA.set({ userId: id })

const clearUserId = () => ReactGA.set({ userId: undefined })

const setPage = (page: string) => {
  ReactGA.set({ page })
  // ReactGA.pageview(page)
  // ga4.default.send({ hitType: 'pageview', page: page })
}

const sendEvent = (category: string, action: string, label: string) => {
  ga4.default.event({
    category,
    action,
    label,
  })
}

const instance = ReactGA

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  initialize,
  setUserId,
  clearUserId,
  sendEvent,
  setPage,
  sendGA4Login,
  sendGAEvent,
  instance,
}
