import { createReducer } from '@reduxjs/toolkit'
import { LoadingStatus } from 'shared/types'
import {
  addProfilePhoto,
  changeProfilePhoto,
  fetchUserInfoData,
  removeProfilePhoto,
  saveUserPhoneFaxChanges,
} from './actions'
import { UserInfo } from './utils'

interface State {
  loading: LoadingStatus
  error?: string | null
  loadingData: LoadingStatus
  userInfo: UserInfo
  fetchLoading: LoadingStatus
  imageLoading: LoadingStatus
  saveDataLoading: LoadingStatus
  modifyImageProcessLoading: LoadingStatus
}
const initialUserInfo: UserInfo = {
  userName: '',
  email: '',
  firstName: '',
  lastName: '',
  phone: '',
  fax: '',
  supervisorFullName: '',
  supervisorEmail: '',
  taxIdOrNationalProviderId: '',
  profilePhotoUri: '',
  taxIds: [],
  cozeva: 0,
  sentToCozeva: '',
  isAdmin: false,
  securityLevel: '',
  healthPlanCodes: [''],
  lastSurveyDate: '',
  associatedOrganizations: [],
}

const initialState: State = {
  loading: LoadingStatus.Idle,
  error: null,
  loadingData: LoadingStatus.Idle,
  userInfo: initialUserInfo,
  fetchLoading: LoadingStatus.Idle,
  imageLoading: LoadingStatus.Idle,
  saveDataLoading: LoadingStatus.Idle,
  modifyImageProcessLoading: LoadingStatus.Idle,
}

export default createReducer(initialState, builder =>
  builder
    .addCase(addProfilePhoto.pending, state => {
      state.loading = LoadingStatus.Pending
      state.error = null
      state.imageLoading = LoadingStatus.Pending
      state.modifyImageProcessLoading = LoadingStatus.Pending
    })
    .addCase(addProfilePhoto.fulfilled, (state, action) => {
      state.loading = LoadingStatus.Succeeded
      state.error = null
      state.imageLoading = LoadingStatus.Succeeded
    })
    .addCase(addProfilePhoto.rejected, (state, action) => {
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
      state.imageLoading = LoadingStatus.Failed
    })

    .addCase(removeProfilePhoto.pending, state => {
      state.loading = LoadingStatus.Pending
      state.error = null
      state.imageLoading = LoadingStatus.Pending
      state.modifyImageProcessLoading = LoadingStatus.Pending
    })
    .addCase(removeProfilePhoto.fulfilled, (state, action) => {
      state.loading = LoadingStatus.Succeeded
      state.error = null
      state.imageLoading = LoadingStatus.Succeeded
    })
    .addCase(removeProfilePhoto.rejected, (state, action) => {
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
      state.imageLoading = LoadingStatus.Failed
    })

    .addCase(changeProfilePhoto.pending, state => {
      state.loading = LoadingStatus.Pending
      state.error = null
      state.imageLoading = LoadingStatus.Pending
      state.modifyImageProcessLoading = LoadingStatus.Pending
    })
    .addCase(changeProfilePhoto.fulfilled, (state, action) => {
      state.loading = LoadingStatus.Succeeded
      state.error = null
      state.imageLoading = LoadingStatus.Succeeded
    })
    .addCase(changeProfilePhoto.rejected, (state, action) => {
      state.loading = LoadingStatus.Failed
      state.error = action.error.message
      state.imageLoading = LoadingStatus.Failed
    })

    .addCase(fetchUserInfoData.pending, (state, action: any) => {
      state.fetchLoading = LoadingStatus.Pending
    })
    .addCase(fetchUserInfoData.rejected, (state, action: any) => {
      state.fetchLoading = LoadingStatus.Failed
      state.userInfo = initialUserInfo
    })
    .addCase(fetchUserInfoData.fulfilled, (state, action) => {
      state.userInfo = action.payload
      if (action.payload.profilePhotoUri) {
        state.userInfo.profilePhotoUri += `?t=${new Date().getTime()}`
      }
      state.fetchLoading = LoadingStatus.Succeeded
      state.modifyImageProcessLoading = LoadingStatus.Succeeded
    })

    .addCase(saveUserPhoneFaxChanges.pending, (state, action: any) => {
      state.saveDataLoading = LoadingStatus.Pending
      state.error = null
    })
    .addCase(saveUserPhoneFaxChanges.rejected, (state, action: any) => {
      state.saveDataLoading = LoadingStatus.Failed
      state.error = action.error.message
    })
    .addCase(saveUserPhoneFaxChanges.fulfilled, (state, action) => {
      state.saveDataLoading = LoadingStatus.Succeeded
      state.error = null
    })
)
