import { createReducer } from '@reduxjs/toolkit'
import { HealthPlanReports } from '../types/data'
import { fetchHealthPlanReportsList } from './actions'

interface State {
  healthPlanReportsList: HealthPlanReports
  healthPlanReportsListLoading: boolean
  healthPlanReportsListFetched: boolean
}

const initialState: State = {
  healthPlanReportsList: {
    currentPage: 0,
    pageSize: 0,
    sortingColumnName: '',
    sortingDirection: 0,
    results: [
      {
        name: '',
        description: '',
        createdDate: '',
        frequency: 68,
        uri: '',
      },
    ],
    totalResults: 0,
  },
  healthPlanReportsListLoading: false,
  healthPlanReportsListFetched: false,
}

export default createReducer(initialState, builder =>
  builder
    .addCase(fetchHealthPlanReportsList.fulfilled, (state, action) => {
      state.healthPlanReportsList = action.payload
      state.healthPlanReportsListLoading = false
      state.healthPlanReportsListFetched = true
    })
    .addCase(fetchHealthPlanReportsList.rejected, state => {
      state.healthPlanReportsListLoading = false
      state.healthPlanReportsListFetched = false
    })
    .addCase(fetchHealthPlanReportsList.pending, state => {
      state.healthPlanReportsListLoading = true
      state.healthPlanReportsListFetched = false
    })
)
