import * as msal from '@azure/msal-browser'

const config = {
  API_URL: process.env.REACT_APP_API_URL,
  API_TOKEN_FIELD: 'authToken',
  API_USERNAME_FIELD: 'username',
  API_ACTIVE_ACCOUNT_FIELD: 'account',
  API_REGISTRATION_EMAIL_FIELD: 'email',
}

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MSAL_CLIENT_ID,
    authority: process.env.REACT_APP_MSAL_AUTHORITY,
    knownAuthorities: [process.env.REACT_APP_MSAL_KNOWN_AUTHORITY],
    redirectUri: process.env.REACT_APP_MSAL_REDIRECT_URI,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
} as msal.Configuration

const configFCMG = {
  API_URL: process.env.REACT_APP_API_URL_FCMG,
  API_TOKEN_FIELD: 'authToken',
  API_USERNAME_FIELD: 'username',
  API_ACTIVE_ACCOUNT_FIELD: 'account',
  API_REGISTRATION_EMAIL_FIELD: 'email',
}

const msalConfigFCMG = {
  auth: {
    clientId: process.env.REACT_APP_MSAL_CLIENT_ID_FCMG,
    authority: process.env.REACT_APP_MSAL_AUTHORITY_FCMG,
    knownAuthorities: [process.env.REACT_APP_MSAL_KNOWN_AUTHORITY_FCMG],
    redirectUri: process.env.REACT_APP_MSAL_REDIRECT_URI,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
} as msal.Configuration

const msalLoginRequest = {
  scopes: ['openid'],
} as msal.PopupRequest

const msalTokenRequest = {
  scopes: ['openid'],
  forceRefresh: false,
} as msal.SilentRequest

export {
  config,
  msalConfig,
  msalLoginRequest,
  msalTokenRequest,
  configFCMG,
  msalConfigFCMG,
}
export { default as i18n } from './i18n'
export { default as theme } from './theme'
export { default as paths } from './paths'
export { default as colors } from './colors'
