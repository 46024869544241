import ManageAccountsIcon from '@material-ui/icons/ListAlt'

import { Loadable } from 'shared/components'
import { ModuleRoute } from 'shared/types'
import { addBasePath } from 'shared/utils'

export const BASE_PATH = '/'

const routes = addBasePath<ModuleRoute>(BASE_PATH, [
  {
    path: '/explanation-of-benefits',
    label: 'Recent EOBs',
    title: 'Recent EOBs',
    Icon: ManageAccountsIcon,
    exact: true,
    component: Loadable({
      component: () => import('./containers/EOB'),
    }),
  },
])

export default routes
