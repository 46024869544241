import { createAsyncThunk, createAction } from '@reduxjs/toolkit'
import { MODULE_NAME } from '../strings'
import ProviderDirectoryApi from './services'
import { SearchProvidersForm, ProviderDetailsPayload } from '../types/data'
import {
  ManageAssociatedProvidersPayload,
  SearchAssociatedProvidersForm,
  SearchReferingProvidersPayload,
} from '../../Patients/components/AuthorizationRequestsTab/ReferringProvider/ReferringProvider.utils'

const api = new ProviderDirectoryApi()

export const fetchProvidersFilters = createAsyncThunk(
  `${MODULE_NAME}/providersFiltersData`,
  async () => api.providersFilters()
)

// Provider Directory
export const fetchProvidersList = createAsyncThunk(
  `${MODULE_NAME}/providersListData`,
  async (payload: SearchProvidersForm) => api.searchProviders(payload)
)

// Referring To on Auth Req
export const fetchProvidersListReferring = createAsyncThunk(
  `${MODULE_NAME}/providersListDataForReferringTo`,
  async (payload: SearchProvidersForm) => api.searchProvidersReferring(payload)
)

export const resetProvidersList = createAction(
  `${MODULE_NAME}/resetProvidersListData`
)

export const fetchProviderDetails = createAsyncThunk(
  `${MODULE_NAME}/providerDetailsData`,
  async (payload: ProviderDetailsPayload) => api.searchProviderDetails(payload)
)

export const removeProviderDetails = createAction<string>(
  `${MODULE_NAME}/removeClaim`
)

export const fetchAssociatedProvidersList = createAsyncThunk(
  `${MODULE_NAME}/associatedProvidersListData`,
  async (payload: SearchAssociatedProvidersForm) =>
    api.searchAssociatedProviders(payload)
)

export const fetchReferringProvidersList = createAsyncThunk(
  `${MODULE_NAME}/referringProvidersListData`,
  async (payload: SearchReferingProvidersPayload) =>
    api.searchReferringProviders(payload)
)

export const removeAssociatedProvider = createAsyncThunk(
  `${MODULE_NAME}/removeAssociatedProvider`,
  async (payload: ManageAssociatedProvidersPayload) =>
    api.removeAssociatedProvider(payload)
)

export const addAssociatedProvider = createAsyncThunk(
  `${MODULE_NAME}/addAssociatedProvider`,
  async (payload: ManageAssociatedProvidersPayload) =>
    api.addAssociatedProvider(payload)
)
