import { RootState } from 'app'

export const getEOBListData = (state: RootState) => state.EOB.eobList

export const getEOBListDataLoading = (state: RootState) =>
  state.EOB.loadingEOBData

export const getEOBListDataFetched = (state: RootState) =>
  state.EOB.isEOBDataFetched

export const getEOBListItemDetails = (state: RootState) =>
  state.EOB.EOBListItemDetails

export const getEOBListItemDetailsLoading = (state: RootState) =>
  state.EOB.EOBListItemDetailsLoading

export const getEOBListItemDetailsFetched = (state: RootState) =>
  state.EOB.EOBListItemDetailsFetched

export const getEOBListOrgs = (state: RootState) => state.EOB.EOBOrgsList
