import { config, configFCMG } from 'config'
import { Api } from 'shared/services'
import { SearchMembersForm } from '../types/data'
import Auth from 'shared/services/Auth'
import { MMN } from '../strings'

const lineOfBusiness = window.localStorage.getItem('lineOfBusiness')

class PatientAssignmentsApi extends Api {
  public async searchProvidersMembersFilters() {
    const userName = Auth.getUsername()

    const API = `${
      lineOfBusiness === MMN ? config.API_URL : configFCMG.API_URL
    }/PatientAssignment/unique-providers/${userName}`
    const requestConfig = {
      params: {
        userName,
      },
    }
    return this.api.get(API, requestConfig).then(response => {
      return response.data
    })
  }

  public async searchPayorsMembersFilters() {
    const API = `${
      lineOfBusiness === MMN ? config.API_URL : configFCMG.API_URL
    }/PatientAssignment/health-plans`

    return this.api.get(API).then(response => {
      return response.data
    })
  }

  public async searchMembers(payload: SearchMembersForm) {
    const currentPage = payload.currentPage + 1
    const username = Auth.getUsername()
    const direction = payload.sortingDirection === 'asc' ? 0 : 1

    const API = `${
      lineOfBusiness === MMN ? config.API_URL : configFCMG.API_URL
    }/PatientAssignment`
    const requestConfig = {
      params: {
        ...payload,
        username,
        currentPage,
        sortingDirection: direction,
      },
    }

    return this.api.get(API, requestConfig).then(response => {
      return response.data
    })
  }
}

export default PatientAssignmentsApi
