import { Provider } from 'react-redux'
import { Router, Route, Switch } from 'react-router-dom'
import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { ThemeProvider, CssBaseline } from '@material-ui/core'
import { theme } from 'config'
import { createBrowserHistory } from 'history'
import { reducer as shared } from 'shared/store'
import { PrivateRoute } from 'shared/components'
import sharedRoutes from 'shared/routes'
import { routes, reducers } from 'modules'
import { useTestEnvironmentPath } from 'shared/utils/useTestEnvironmentPath'
import Main from './Main'
import TestBarWithData from 'shared/components/TestBarWithData/TestBarWithData'
import googleAnalytics from 'config/google-analytics'
import { useEffect, useState } from 'react'
import { useAuth } from 'shared/hooks'
import Maintenance from 'shared/components/Maintenance'
import { handleRedirectResponse } from "shared/store/auth/actions";

const history = createBrowserHistory()

export const store = configureStore({
  reducer: combineReducers({ shared, ...reducers }),
})

export type RootState = ReturnType<typeof store.getState>

const App = () => {
  const maintenanceStartDate = new Date('November 27, 2024 16:00:00')
  const maintenanceEndDate = new Date('November 29, 2024 9:00:00')

  const getIsMaintenance = () => {
    const now = new Date()
    return (
      now.getTime() >= maintenanceStartDate.getTime() &&
      now.getTime() <= maintenanceEndDate.getTime()
    )
  }
  const [isMaintenance, setIsMaintenance] = useState(getIsMaintenance())
  useEffect(() => {
    const interval = setInterval(
      () => setIsMaintenance(getIsMaintenance()),
      1000
    )
    return () => {
      clearInterval(interval)
    }
  }, [])

  const isTestEnv = useTestEnvironmentPath()
  const { isAuthenticated } = useAuth()
  googleAnalytics.initialize()

  useEffect(() => {
    history.listen(location => {
      if (isAuthenticated) googleAnalytics.setPage(location.pathname)
    })
  })

    // Add the useEffect to handle redirect response
    useEffect(() => {
      handleRedirectResponse();
    }, []);

  if (isMaintenance) {
    return <Maintenance />
  } else {
    return (
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router history={history}>
            {isTestEnv && <TestBarWithData />}
            <Main>
              <Switch>
                {sharedRoutes.map(route => (
                  <Route key={`publicRoute-${route.path}`} {...route} />
                ))}
                {routes.map(route => (
                  <PrivateRoute key={`route-${route.path}`} {...route} />
                ))}
              </Switch>
            </Main>
          </Router>
        </ThemeProvider>
      </Provider>
    )
  }
}

export default App
