import { config } from 'config'

class Auth {
  static setToken(token: string) {
    return localStorage.setItem(config.API_TOKEN_FIELD, token)
  }

  static getToken() {
    return localStorage.getItem(config.API_TOKEN_FIELD)
  }

  static setUsername(username: string) {
    return localStorage.setItem(config.API_USERNAME_FIELD, username)
  }

  static getUsername() {
    return localStorage.getItem(config.API_USERNAME_FIELD)
  }

  static setActiveAccount(account: string) {
    return localStorage.setItem(config.API_ACTIVE_ACCOUNT_FIELD, account)
  }

  static getActiveAccount() {
    return localStorage.getItem(config.API_ACTIVE_ACCOUNT_FIELD)
  }

  static setRegistrationEmail(email: string | string[]) {
    return localStorage.setItem(
      config.API_REGISTRATION_EMAIL_FIELD,
      email as string
    )
  }

  static getRegistrationEmail() {
    return localStorage.getItem(config.API_REGISTRATION_EMAIL_FIELD)
  }

  static isAuthenticated() {
    return !!Auth.getToken()
  }
}

export default Auth
