import { RootState } from 'app'

export const getPatientsData = (state: RootState) => state.Patients.patientsList
export const getPatientsDataLoading = (state: RootState) =>
  state.Patients.loadingData
export const getPatientsIsDataFetched = (state: RootState) =>
  state.Patients.isDataFetched

export const getPatientDataLoading = (state: RootState) =>
  state.Patients.loadingPatientData

export const getPatientsTreatignRelationshipDataLoading = (state: RootState) =>
  state.Patients.patientsTreatingRelationship.loadingData
export const getPatientsTreatignRelationshipDataFetched = (state: RootState) =>
  state.Patients.patientsTreatingRelationship.isDataFetched

export const getSelectedPatientId = (state: RootState) =>
  state.Patients.selectedPatientId
export const getSelectedPatientSpecialKey = (state: RootState) =>
  state.Patients.selectedPatientSpecialKey
export const getPatientTreatingRelationship = (state: RootState) =>
  state.Patients.patientTreatingRelationship
export const getSelectedPatientData = (state: RootState) =>
  state.Patients.selectedPatientData
export const getEligibilityPatientDataById = (state: RootState) =>
  state.Patients.currentPatient

export const getClaimsListData = (state: RootState) => state.Patients.claimsList
export const getClaimDetails = (state: RootState) => state.Patients.claimDetails
export const getClaimsSubTableLoading = (state: RootState) =>
  state.Patients.claimSubTableLoading.loadingData
export const getClaimsSubTableIsDataFetched = (state: RootState) =>
  state.Patients.claimSubTableLoading.isDataFetched

export const getLabsListData = (state: RootState) => state.Patients.labsList
export const getLabsDetails = (state: RootState) => state.Patients.labsDetails
export const getLabsSubTableLoading = (state: RootState) =>
  state.Patients.labsSubTableLoading.loadingData
export const getLabsSubTableIsDataFetched = (state: RootState) =>
  state.Patients.labsSubTableLoading.isDataFetched

export const getPatientServices = (state: RootState) =>
  state.Patients.patientServices
export const getPatientServicesLoading = (state: RootState) =>
  state.Patients.loadingData
export const getPatientServicesIsDataFetched = (state: RootState) =>
  state.Patients.isDataFetched

export const getAuthorizationsListData = (state: RootState) =>
  state.Patients.authorizationsList
export const getAuthorizationDetails = (state: RootState) =>
  state.Patients.authorizationDetails

export const getPathologyListData = (state: RootState) =>
  state.Patients.pathologyList

export const getAuthorizationsSubTableLoading = (state: RootState) =>
  state.Patients.authorizationSubTableLoading

export const getCurrentAuthorizationRequestsStep = (state: RootState) =>
  state.Patients.currentAuthorizationRequestsStep

export const getServiceRequestsList = (state: RootState) =>
  state.Patients.serviceRequestsList

export const getServiceRequestsListLoading = (state: RootState) =>
  state.Patients.serviceRequestsListLoading

export const getDiagnosisList = (state: RootState) =>
  state.Patients.diagnosisList

export const getDiagnosisListLoading = (state: RootState) =>
  state.Patients.diagnosisListLoading

export const getPlaceOfServiceList = (state: RootState) =>
  state.Patients.placeOfServiceList

export const getNewAuthRequest = (state: RootState) =>
  state.Patients.newAuthorizationRequest

export const getNewAuthReqStatus = (state: RootState) =>
  state.Patients.newAuthReqStatus

export const getRxList = (state: RootState) => state.Patients.rxList

export const getTranscriptionsListData = (state: RootState) =>
  state.Patients.transcriptionsList
export const getTranscriptionsDetails = (state: RootState) =>
  state.Patients.transcriptionsDetails
export const getTranscriptionsSubTableLoading = (state: RootState) =>
  state.Patients.transcriptionsSubTableLoading.loadingData
export const getTranscriptionsSubTableIsDataFetched = (state: RootState) =>
  state.Patients.transcriptionsSubTableLoading.isDataFetched

export const getRadiologyListData = (state: RootState) =>
  state.Patients.radiologyList
export const getRadiologyDetails = (state: RootState) =>
  state.Patients.radiologyDetails
export const getRadiologySubTableLoading = (state: RootState) =>
  state.Patients.radiologySubTableLoading.loadingData
export const getRadiologySubTableIsDataFetched = (state: RootState) =>
  state.Patients.radiologySubTableLoading.isDataFetched
export const getIssueReportLoading = (state: RootState) =>
  state.Patients.issueReportLoading
export const getIssueReportSubmitted = (state: RootState) =>
  state.Patients.issueReportSubmitted

export const getAuthHealthPlanType = (state: RootState) =>
  state.Patients.authHealPlanType
export const getAuthRetroactiveStatus = (state: RootState) =>
  state.Patients.isAuthRetroactive

export const getIsBabylon = (state: RootState) => state.Patients.isBabylon
