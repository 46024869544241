import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { MODULE_NAME } from '../strings'
import PatientsApi from './services'
import {
  SearchPatientsForm,
  SearchClaimsList,
  searchClaimDetails,
  SearchPatientByIdForm,
  SearchPatientServicesForm,
  searchAuthorizationDetails,
  SearchAuthorizationsList,
  SearchServiceRequestsPayload,
  SearchDiagnosisPayload,
  TreatingRelationshipForm,
  SetTreatingRelationshipForm,
  Patient,
  RxListPayload,
  SearchTranscriptionsDetails,
  SearchTranscriptionsList,
  SearchLabs,
  SearchLabsDetails,
  SearchPathologyList,
  SearchRadiology,
  SearchRadiologyDetails,
  CheckAuthHealthPlanTypePayload,
} from '../types/data'

const api = new PatientsApi()

export const fetchPatients = createAsyncThunk(
  `${MODULE_NAME}/data`,
  async (payload: SearchPatientsForm) => api.searchPatients(payload)
)

export const fetchClaimsData = createAsyncThunk(
  `${MODULE_NAME}/claimsData`,
  async (payload: SearchClaimsList) => api.searchClaimsList(payload)
)

export const fetchClaimDetails = createAsyncThunk(
  `${MODULE_NAME}/claimDetailData`,
  async (payload: searchClaimDetails) => api.searchClaimDetails(payload)
)

export const removeLabsDetails = createAction<string>(
  `${MODULE_NAME}/removeLabs`
)

export const fetchLabsDetails = createAsyncThunk(
  `${MODULE_NAME}/labsDetailData`,
  async (payload: SearchLabsDetails) => api.searchLabsDetails(payload)
)

export const fetchLabsData = createAsyncThunk(
  `${MODULE_NAME}/fetchLabsData`,
  async (payload: SearchLabs) => api.searchLabsData(payload)
)

export const removeClaimDetails = createAction<string>(
  `${MODULE_NAME}/removeClaim`
)

export const fetchAuthorizationDetails = createAsyncThunk(
  `${MODULE_NAME}/authorizationDetailData`,
  async (payload: searchAuthorizationDetails) =>
    api.searchAuthorizationDetails(payload)
)

export const fetchAuthorizationsData = createAsyncThunk(
  `${MODULE_NAME}/authorizationsData`,
  async (payload: SearchAuthorizationsList) =>
    api.searchAuthorizationsList(payload)
)

export const removeAuthorizationDetails = createAction<string>(
  `${MODULE_NAME}/removeAuthorization`
)

export const fetchPathologyData = createAsyncThunk(
  `${MODULE_NAME}/pathologyData`,
  async (payload: SearchPathologyList) => api.searchPathologyList(payload)
)

export const setSelectedPatientId = createAction<string>(
  `${MODULE_NAME}/setselectedpatientid`
)
export const setSelectedPatientSpecialKey = createAction<string>(
  `${MODULE_NAME}/setselectedpatientspecialkey`
)
export const setSelectedPatientData = createAction<Patient>(
  `${MODULE_NAME}/setSelectedPatientData`
)

export const fetchPatientTreatingRelationship = createAsyncThunk(
  `${MODULE_NAME}/treatingrelationship`,
  async (payload: TreatingRelationshipForm) =>
    api.getPatientTreatingRelationship(payload)
)

export const setTreatingRelation = createAsyncThunk(
  `${MODULE_NAME}/settreatingrelationship`,
  async (payload: SetTreatingRelationshipForm) =>
    api.setTreatingRelationForPatient(payload)
)

export const fetchPatientById = createAsyncThunk(
  `${MODULE_NAME}/profiledata`,
  async (payload: SearchPatientByIdForm) => api.searchPatientById(payload)
)

export const fetchPatientServices = createAsyncThunk(
  `${MODULE_NAME}/patientservicesdata`,
  async (payload: SearchPatientServicesForm) =>
    api.searchPatientServices(payload)
)

export const setCurrentAuthorizationRequestsStep = createAction<number>(
  `${MODULE_NAME}/setCurrentAuthorizationRequestsStep`
)

export const fetchServiceRequestsList = createAsyncThunk(
  `${MODULE_NAME}/serviceRequestsList`,
  async (payload: SearchServiceRequestsPayload) =>
    api.searchServiceRequestsList(payload)
)

export const fetchDiagnosisList = createAsyncThunk(
  `${MODULE_NAME}/diagnosisList`,
  async (payload: SearchDiagnosisPayload) => api.searchDiagnosisList(payload)
)

export const fetchPlaceOfServiceCodes = createAsyncThunk(
  `${MODULE_NAME}/placeOfServiceList`,
  async () => api.fetchPlaceOfServiceList()
)

export const createNewAuthorizationRequest = createAsyncThunk(
  `${MODULE_NAME}/newAuthRequest`,
  async (payload: any) => api.createNewAuthorizationRequest(payload)
)

export const searchRxList = createAsyncThunk(
  `${MODULE_NAME}/rxList`,
  async (payload: RxListPayload) => api.searchRxList(payload)
)

export const fetchTranscriptionsData = createAsyncThunk(
  `${MODULE_NAME}/transcriptionsData`,
  async (payload: SearchTranscriptionsList) =>
    api.searchTranscriptionsList(payload)
)

export const fetchTranscriptionsDetails = createAsyncThunk(
  `${MODULE_NAME}/transcriptionsDetailData`,
  async (payload: SearchTranscriptionsDetails) =>
    api.searchTranscriptionsDetails(payload)
)

export const removeTranscriptionsDetails = createAction<string>(
  `${MODULE_NAME}/removeTranscription`
)

export const removeRadiologyDetails = createAction<string>(
  `${MODULE_NAME}/removeRadiology`
)

export const fetchRadiologyDetails = createAsyncThunk(
  `${MODULE_NAME}/radiologyDetailData`,
  async (payload: SearchRadiologyDetails) => api.searchRadiologyDetails(payload)
)

export const fetchRadiologyData = createAsyncThunk(
  `${MODULE_NAME}/fetchRadiologyData`,
  async (payload: SearchRadiology) => api.searchRadiologyData(payload)
)

export const checkAuthHealthPlanType = createAsyncThunk(
  `${MODULE_NAME}/checkAuthHealthPlanType`,
  async (payload: CheckAuthHealthPlanTypePayload) =>
    api.checkAuthHealthPlanType(payload)
)

export const setAuthHealthPlanTypeToDefault = createAction(
  `${MODULE_NAME}/setAuthHealthPlanTypeToDefault`
)

export const isAuthRequiredForCpt = createAsyncThunk(
  `${MODULE_NAME}/isAuthRequiredForCpt`,
  async (payload: {
    code: string
    company: number
    referringProvider: string | undefined
    referringToProvider: string | undefined
  }) => api.isAuthRequiredForCpt(payload)
)

export const setAuthRetroactiveStatus = createAction<boolean | null>(
  `${MODULE_NAME}/setAuthRetroactiveStatus`
)

export const checkIfBabylon = createAsyncThunk(
  `${MODULE_NAME}/checkIfBabylon`,
  async (payload: { code: string; addressSequence: number }) =>
    api.checkIfBabylon(payload)
)

export const resetBabylonCheck = createAction(
  `${MODULE_NAME}/resetBabylonCheck`
)
