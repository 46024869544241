import { Api, Auth } from 'shared/services'
import { config, configFCMG } from 'config'
import { RegistrationPayload } from '../../components/RegistrationForm/RegistrationForm.utils'
import {
  CreateAccountRequestValues,
  CreateUserPayload,
  UserRegistrationRequestPayload,
  VerifyEmailPayload,
} from '../../components/Registration/Registration.utils'
import { CreateIssueReportPayload } from 'shared/components/Navigation/NavigationForm.utils'
import { MMN, LineOfBusiness } from 'modules/Patients/strings'
import { SubmitSurveyPayload } from 'shared/types'

const lineOfBusiness = window.localStorage.getItem('lineOfBusiness')

export default class AuthApi extends Api {
  public appConfig =
    lineOfBusiness === MMN ? config.API_URL : configFCMG.API_URL

  public createAccount(payload: RegistrationPayload) {
    const lineOfBusiness = window.localStorage.getItem('lineOfBusiness')
    const { firstName, lastName, username, email, taxId, phone, fax } = payload
    const API = `${
      lineOfBusiness === MMN ? config.API_URL : configFCMG.API_URL
    }/User/account-request`

    const requestConfig = {
      params: {
        firstName,
        lastName,
        userName: username,
        email,
        taxIdOrNationalProviderId: taxId,
        phone,
        fax,
      },
    }

    return this.api.post(API, requestConfig.params)
  }

  public async acceptRegistrationRequest(payload: CreateAccountRequestValues) {
    const API = `${this.appConfig}/User/approve-account`
    const accountRequestId = Number(payload.accountRequestId)

    const requestConfig = {
      params: {
        accountRequestId: accountRequestId,
        supervisorId: payload.supervisorId && +payload.supervisorId,
        code: payload.code,
      },
    }
    return this.api
      .put(API, requestConfig.params)
      .then(response => response.data)
  }

  public async denyRegistrationRequest(payload: CreateAccountRequestValues) {
    const API = `${this.appConfig}/User/deny-account`
    const accountRequestId = Number(payload.accountRequestId)

    const requestConfig = {
      params: {
        accountRequestId: accountRequestId,
        supervisorId: payload.supervisorId && +payload.supervisorId,
        code: payload.code,
      },
    }

    return this.api.put(API, requestConfig.params).then(response => {
      return response.data
    })
  }

  public async verifyEmail(payload: VerifyEmailPayload) {
    const appConfig =
      payload.lineOfBusiness.toString() === LineOfBusiness.MMN.toString()
        ? config.API_URL
        : configFCMG.API_URL

    const API = `${appConfig}/User/verify-email`

    const userId = Number(payload.userId)

    const requestConfig = {
      params: {
        userId: userId,
        code: payload.code,
      },
    }
    return this.api.put(API, requestConfig.params).then(response => {
      return response.data
    })
  }

  public async createUser(payload: CreateUserPayload) {
    const appConfig =
      payload.lineOfBusiness.toString() === LineOfBusiness.MMN.toString()
        ? config.API_URL
        : configFCMG.API_URL
    const API = `${appConfig}/User/create-user`
    const userId = Number(payload.userId)

    const requestConfig = {
      params: {
        userId: userId,
        password: payload.password,
      },
    }

    return this.api.post(API, requestConfig.params).then(response => {
      return response.data
    })
  }

  public async getUserDataForRegistrationRequest(
    payload: UserRegistrationRequestPayload
  ) {
    const id = payload.id
    const API = `${this.appConfig}/User/registration/${id}`
    const requestConfig = {
      params: {
        ...payload,
      },
    }

    return this.api.get(API, requestConfig).then(response => {
      return response.data
    })
  }

  public async createIssueReport(payload: CreateIssueReportPayload) {
    const API = `${this.appConfig}/User/report`
    const { userName, module, issueDescription, attachment } = payload

    const formData = new FormData()
    if (userName) formData.append('userName', userName)
    if (module) formData.append('module', module)
    if (issueDescription) formData.append('issueDescription', issueDescription)
    if (attachment) formData.append('attachment', [...attachment][0])

    return this.api.put(API, formData).then(response => response.data)
  }

  public async getCozeva() {
    const API = `${this.appConfig}/user/cozeva`
    return this.api.get(API).then(response => response.data)
  }

  public async submitSurvey(payload: SubmitSurveyPayload) {
    const API = `${this.appConfig}/user/survey?isFeedbackGiven=${
      payload.isFeedbackGiven
    }&userName=${Auth.getUsername()}`

    delete payload.isFeedbackGiven

    return this.api.post(API, payload).then(response => response.data)
  }

  public async updateUserActiveStatus() {
    const API = `${this.appConfig}/user/last-login/${Auth.getUsername()}`

    return this.api.put(API).then(response => response.data)
  }
}
