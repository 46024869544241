import { RootState } from 'app'

export const getIsDataLoading = (state: RootState) =>
  state.PatientAssignments.loadingData
export const getIsDataFetched = (state: RootState) =>
  state.PatientAssignments.isDataFetched

export const getMembersProvidersFilters = (state: RootState) =>
  state.PatientAssignments.membersFiltersPoviders

export const getMembersPayorsFilters = (state: RootState) =>
  state.PatientAssignments.membersPayorsFilters

export const getMembersList = (state: RootState) =>
  state.PatientAssignments.membersList