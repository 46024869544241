import { useState, useEffect } from 'react'
import moment from 'moment'
import { Bar } from './TestBarWithData.style'

const TestBarWithData: React.FC = () => {
  const [actualDate, setActualDate] = useState(
    moment().format('MMMM Do YYYY, h:mm:ss a')
  )

  useEffect(() => {
    const intervalID = setInterval(() => {
      setActualDate(moment().format('MMMM Do YYYY, h:mm:ss a'))
    }, 1000)

    return () => {
      clearTimeout(intervalID)
    }
  }, [])

  return <Bar>{actualDate}</Bar>
}

export default TestBarWithData
