import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Auth } from 'shared/services'
import { fetchUserInfoData } from 'modules/UserAccount/store/actions'
import { fetchDCEAccessCheck } from 'modules/DCEPatientRoster/store/actions'
import { MMN } from 'shared/strings'

const Main = (props: any) => {
  const dispatch = useDispatch()
  const userName = Auth.getUsername()
  const lineOfBusiness = window.localStorage.getItem('lineOfBusiness')

  useEffect(() => {
    const isAuthenticated = Auth.isAuthenticated()
    if (isAuthenticated && typeof userName === 'string') {
      dispatch(fetchUserInfoData(userName))
    }
    if (lineOfBusiness === MMN) dispatch(fetchDCEAccessCheck())
  }, [dispatch, userName])

  return props.children
}

export default Main
