import { Loadable } from 'shared/components'
import { ModuleRoute } from 'shared/types'
import { addBasePath } from 'shared/utils'
import AssignmentIcon from '@material-ui/icons/HowToReg'

export const BASE_PATH = '/'

const routes = addBasePath<ModuleRoute>(BASE_PATH, [
  {
    path: '/assignments',
    label: 'navigation.assignments',
    title: 'Patient Assignments',
    Icon: AssignmentIcon,
    exact: true,
    component: Loadable({
      component: () => import('./containers/PatientAssignments'),
    }),
  },
])

export default routes
