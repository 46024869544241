import { Api, Auth } from 'shared/services'
import { config, configFCMG } from 'config'
import { MMN } from '../strings'
import { DCERosterPayload } from '../types/data'

const lineOfBusiness = window.localStorage.getItem('lineOfBusiness')

export default class DCERosterApi extends Api {
  public appConfig =
    lineOfBusiness === MMN ? config.API_URL : configFCMG.API_URL

  public async getDCEPatientRosters(payload: DCERosterPayload) {
    const API = `${this.appConfig}/dce`
    const username = Auth.getUsername()

    const { currentPage, sortingDirection, PCPLastName, patientId } = payload

    const requestConfig = {
      params: {
        ...payload,
        currentPage: currentPage + 1,
        sortingDirection: sortingDirection === 'asc' ? 1 : 0,
        username: username,
        PcpLastName: PCPLastName,
        PatientId: patientId,
      },
    }

    return this.api.get(API, requestConfig).then(response => response.data)
  }

  public async dceAccessCheck() {
    const username = Auth.getUsername()
    if (username) {
      const API = `${this.appConfig}/dce/access/${username}/`

      return this.api.get(API).then(response => response.data)
    }
  }

  public async getDCEPatientProvidersList(searchValue: string) {
    const API = `${this.appConfig}/dce/providers/${searchValue}/pcpname`

    return this.api.get(API).then(response => response.data)
  }
}
