import * as msal from '@azure/msal-browser'
import {
  msalConfig,
  msalConfigFCMG,
  msalLoginRequest,
  msalTokenRequest,
} from 'config'
import { MMN } from 'modules/Patients/strings'

class Msal {
  private static instance: Msal
  private msalObject: msal.PublicClientApplication

  private constructor() {
    const lineOfBusiness = window.localStorage.getItem('lineOfBusiness')

    this.msalObject = new msal.PublicClientApplication(
      lineOfBusiness === MMN ? msalConfig : msalConfigFCMG
    )
  }

  public static renewInstance(): Msal {
    Msal.instance = new Msal()

    return Msal.instance
  }

  public static getInstance(): Msal {
    if (!Msal.instance) {
      Msal.instance = new Msal()
    }

    return Msal.instance
  }

  public getAllAccounts() {
    return this.msalObject.getAllAccounts()
  }

  public getAccountByHomeId(
    accountId: string | undefined
  ): msal.AccountInfo | null {
    if (!accountId) return null
    return this.msalObject.getAccountByHomeId(accountId)
  }

  public getActiveAccount(): msal.AccountInfo | null {
    return this.msalObject.getActiveAccount()
  }

  public setActiveAccount(accountId: string | undefined): void {
    const account = this.getAccountByHomeId(accountId)
    if (account) {
      this.msalObject.setActiveAccount(account)
    }
  }

  public loginPopup(): Promise<msal.AuthenticationResult> {
    return this.msalObject.loginPopup(msalLoginRequest)
  }

  public loginRedirect(): void {
    this.msalObject.loginRedirect()
  }

  // Add the handleRedirectPromise method
  public handleRedirectPromise(): Promise<msal.AuthenticationResult | null> {
    return this.msalObject.handleRedirectPromise()
  }

  public async logoutPopup(logoutRequest: any): Promise<void> {
    return this.msalObject.logoutPopup(logoutRequest)
  }

  public getTokenPopup(): Promise<msal.AuthenticationResult> {
    return this.msalObject.acquireTokenPopup(msalLoginRequest)
  }

  public getSilentToken(
    forceRefresh = false
  ): Promise<msal.AuthenticationResult> {
    return this.msalObject.acquireTokenSilent({
      ...msalTokenRequest,
      forceRefresh,
    })
  }
}

export default Msal
