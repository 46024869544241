import Auth from 'shared/services/Auth'
import { config, configFCMG } from 'config'
import { Api } from 'shared/services'
import { ProfilePhotoPayload, SavePhoneFaxPayload } from './utils'
import { MMN } from '../strings'

const lineOfBusiness = window.localStorage.getItem('lineOfBusiness')
class UserAccountApi extends Api {
  public async addProfilePhoto(payload: ProfilePhotoPayload) {
    const username = Auth.getUsername()
    const API = `${
      lineOfBusiness === MMN ? config.API_URL : configFCMG.API_URL
    }/User/photo`

    let formDataAdd = new FormData()
    if (username) formDataAdd.append('UserName', username)
    formDataAdd.append('Photo', payload.profilePhotoUri)

    const requestConfig = {
      data: formDataAdd,
    }
    return this.api.post(API, requestConfig.data)
  }

  public async changeProfilePhoto(payload: ProfilePhotoPayload) {
    const username = Auth.getUsername()
    const API = `${
      lineOfBusiness === MMN ? config.API_URL : configFCMG.API_URL
    }/User/photo`
    let formData = new FormData()
    if (username) formData.append('UserName', username)
    formData.append('Photo', payload.profilePhotoUri)

    const requestConfig = {
      data: formData,
    }
    return this.api.put(API, requestConfig.data)
  }

  public async removeProfilePhoto() {
    const username = Auth.getUsername()
    const API = `${
      lineOfBusiness === MMN ? config.API_URL : configFCMG.API_URL
    }/User/photo`
    const requestConfig = {
      params: {
        userName: username,
      },
    }
    const response = await this.api.delete(API, requestConfig)
    return response.data
  }

  public async getUserInfo(payload: string | null) {
    const API = `${
      lineOfBusiness === MMN ? config.API_URL : configFCMG.API_URL
    }/User/${payload}`
    const requestConfig = {
      params: {
        userName: payload,
      },
    }

    return this.api.get(API, requestConfig).then(response => {
      return response.data
    })
  }

  public async saveUserPhoneFaxChanges(payload: SavePhoneFaxPayload) {
    const username = Auth.getUsername()
    const API = `${
      lineOfBusiness === MMN ? config.API_URL : configFCMG.API_URL
    }/User/${username}`
    const requestConfig = {
      params: {
        phone: payload.phone || null,
        fax: payload.fax || null,
      },
    }
    return this.api.put(API, requestConfig.params)
  }
}
export default UserAccountApi
