import { createAsyncThunk } from '@reduxjs/toolkit'
import { MODULE_NAME } from '../strings'
import UserAccountApi from './services'
import { ProfilePhotoPayload, SavePhoneFaxPayload } from './utils'

const api = new UserAccountApi()

export const addProfilePhoto = createAsyncThunk(
  `${MODULE_NAME}/addProfilePhoto`,
  async (payload: ProfilePhotoPayload) => api.addProfilePhoto(payload)
)

export const changeProfilePhoto = createAsyncThunk(
  `${MODULE_NAME}/changeProfilePhoto`,
  async (payload: ProfilePhotoPayload) => api.changeProfilePhoto(payload)
)

export const removeProfilePhoto = createAsyncThunk(
  `${MODULE_NAME}/removeProfilePhoto`,
  async () => api.removeProfilePhoto()
)

export const fetchUserInfoData = createAsyncThunk(
  `${MODULE_NAME}/getUserInfo`,
  async (payload: string) => api.getUserInfo(payload)
)

export const saveUserPhoneFaxChanges = createAsyncThunk(
  `${MODULE_NAME}/saveUserPhoneFaxChanges`,
  async (payload: SavePhoneFaxPayload) => api.saveUserPhoneFaxChanges(payload)
)