import { Api, Auth } from 'shared/services'
import { EOBDetailsPayload, EOBListReqPayload } from '../types/data'
import { config, configFCMG } from 'config'
import { MMN } from '../strings'

const lineOfBusiness = window.localStorage.getItem('lineOfBusiness')

export default class EOBApi extends Api {
  public appConfig =
    lineOfBusiness === MMN ? config.API_URL : configFCMG.API_URL

  public async searchEOBList(payload: EOBListReqPayload) {
    const {
      page,
      rowsPerPage,
      order,
      orderBy,
      organizationName,
      checkNumber,
    } = payload
    const username = Auth.getUsername()
    const API = `${this.appConfig}/EOB/${username}/claims`

    const requestConfig = {
      params: {
        currentPage: page + 1,
        pageSize: rowsPerPage,
        sortByColumn: orderBy,
        sortingDirection: order === 'asc' ? 0 : 1,
        checkNumber,
        organizationName,
      },
    }

    return this.api.get(API, requestConfig).then(response => response.data)
  }

  public async getEOBItemDetails(payload: EOBDetailsPayload[]) {
    const API = `${this.appConfig}/EOB/details`

    const requestConfig = payload.map(el => ({
      checkNumber: +el.checkNumber,
      orgID: el.orgID,
    }))

    return this.api.post(API, requestConfig).then(response => response.data)
  }

  public async getEOBOrgs() {
    const username = Auth.getUsername()
    const API = `${this.appConfig}/User/${username}/organizations`

    return this.api.get(API).then(response => response.data)
  }
}
