import { createReducer } from '@reduxjs/toolkit'
import { LoadingStatus } from 'shared/types'
import { ReducerStatus } from 'shared/utils/reducerStatus'
import {
  setSelectedPatientId,
  removeClaimDetails,
  fetchClaimsData,
  fetchClaimDetails,
  fetchPatientById,
  fetchPatients,
  fetchPatientServices,
  fetchAuthorizationsData,
  fetchPatientTreatingRelationship,
  removeAuthorizationDetails,
  fetchAuthorizationDetails,
  setSelectedPatientData,
  setCurrentAuthorizationRequestsStep,
  fetchServiceRequestsList,
  fetchDiagnosisList,
  setTreatingRelation,
  fetchPlaceOfServiceCodes,
  fetchPathologyData,
  createNewAuthorizationRequest,
  searchRxList,
  fetchTranscriptionsData,
  fetchTranscriptionsDetails,
  removeTranscriptionsDetails,
  fetchLabsData,
  fetchLabsDetails,
  removeLabsDetails,
  fetchRadiologyData,
  fetchRadiologyDetails,
  removeRadiologyDetails,
  setSelectedPatientSpecialKey,
  checkAuthHealthPlanType,
  setAuthHealthPlanTypeToDefault,
  setAuthRetroactiveStatus,
  resetBabylonCheck,
  checkIfBabylon,
} from './actions'
import {
  SearchPatientsPayload,
  ClaimsListPayload,
  ClaimDetailsPayload,
  PatientProfile,
  PatientService,
  Patient,
  ServiceRequestItem,
  DiagnosisItem,
  PlaceOfService,
  LabsListPayload,
  LabsDetailsPayload,
  RxList,
  TranscriptionsListPayload,
  TranscriptionsDetailsPayload,
  PathologyListPayload,
  AuthorizationsListPayload,
  RadiologyListPayload,
  RadiologyDetailsPayload,
} from '../types/data'
import { AuthorizationDetails } from 'shared/types/data'
import {
  createIssueReport,
  reportSubmittedAction,
} from 'shared/store/auth/actions'

interface State {
  something: string
  loading: LoadingStatus
  loadingPatientData: LoadingStatus
  error?: string | null
  patientsList: SearchPatientsPayload
  selectedPatientId: string | null
  selectedPatientSpecialKey: string | number | null
  patientTreatingRelationship: boolean
  selectedPatientData: Patient
  loadingData: boolean
  isDataFetched: boolean
  patientsTreatingRelationship: {
    loadingData: boolean
    isDataFetched: boolean
  }
  claimSubTableLoading: {
    loadingData: boolean
    componentID: string
    isDataFetched: boolean
  }
  labsSubTableLoading: {
    loadingData: boolean
    componentID: string
    isDataFetched: boolean
  }
  claimsList: ClaimsListPayload
  labsList: LabsListPayload
  claimDetails: ClaimDetailsPayload[]
  labsDetails: LabsDetailsPayload[]
  currentPatient: PatientProfile
  patientServices: PatientService[]
  authorizationSubTableLoading: LoadingStatus
  authorizationsList: AuthorizationsListPayload
  authorizationDetails: AuthorizationDetails[]
  pathologyList: PathologyListPayload
  currentAuthorizationRequestsStep: number
  serviceRequestsList: ServiceRequestItem[]
  serviceRequestsListLoading: boolean
  diagnosisList: DiagnosisItem[]
  diagnosisListLoading: boolean
  placeOfServiceList: PlaceOfService[]
  newAuthorizationRequest: AuthorizationDetails
  newAuthReqStatus: ReducerStatus
  rxList: RxList
  transcriptionsSubTableLoading: {
    loadingData: boolean
    componentID: string
    isDataFetched: boolean
  }
  transcriptionsList: TranscriptionsListPayload
  transcriptionsDetails: TranscriptionsDetailsPayload[]
  radiologySubTableLoading: {
    loadingData: boolean
    componentID: string
    isDataFetched: boolean
  }
  radiologyList: RadiologyListPayload
  radiologyDetails: RadiologyDetailsPayload[]
  issueReportLoading: {
    loadingData: boolean
    isDataFetched: boolean
  }
  issueReportSubmitted: {
    isReportSubmitted: boolean
  }
  authHealPlanType: number
  isAuthRetroactive: boolean | null
  isBabylon: { behavioralProgram: string }
}

const initialState: State = {
  something: '',
  loading: LoadingStatus.Idle,
  loadingPatientData: LoadingStatus.Idle,
  error: null,
  selectedPatientId: null,
  selectedPatientSpecialKey: null,
  patientTreatingRelationship: false,
  selectedPatientData: {
    id: '',
    shortId: '',
    firstName: '',
    lastName: '',
    middleName: '',
    dateOfBirth: '',
    healthPlanCode: '',
    gender: '',
    specialKey: '',
    terminationDate: '',
    pcpFirstName: '',
    pcpLastName: '',
    isMeritagePatient: false,
    isClinicalRecordAvailable: false,
  },
  patientsList: {
    currentPage: 0,
    pageSize: 10,
    sortingColumnName: 'firstName',
    results: [
      {
        id: '',
        shortId: '',
        firstName: '',
        lastName: '',
        middleName: '',
        dateOfBirth: '',
        gender: '',
        healthPlanCode: '',
        specialKey: '',
        terminationDate: '',
        pcpFirstName: '',
        pcpLastName: '',
        isMeritagePatient: false,
        isClinicalRecordAvailable: false,
      },
    ],
    totalResults: 0,
  },
  currentPatient: {
    patientId: '',
    patientShortId: '',
    dateOfBirth: '',
    healthPlan: '',
    healthPlanName: '',
    eligibilityStart: '',
    eligibilityTermination: '',
    benefitOption: '',
    employerGroup: '',
    phoneNumber: '',
    providerFullName: '',
    pcpStartDate: '',
    addressFirstLine: '',
    addressSecondLine: '',
    city: '',
    state: '',
    zip: '',
    riskPool: '',
    primaryCareProvID: '',
  },
  patientServices: [
    {
      id: '',
      serviceCategory: '',
      copayAmount: 0,
    },
  ],
  loadingData: true,
  isDataFetched: false,
  patientsTreatingRelationship: {
    loadingData: true,
    isDataFetched: false,
  },
  claimSubTableLoading: {
    loadingData: true,
    componentID: '',
    isDataFetched: false,
  },
  labsSubTableLoading: {
    loadingData: true,
    componentID: '',
    isDataFetched: false,
  },
  radiologySubTableLoading: {
    loadingData: true,
    componentID: '',
    isDataFetched: false,
  },
  transcriptionsSubTableLoading: {
    loadingData: true,
    componentID: '',
    isDataFetched: false,
  },
  authorizationSubTableLoading: LoadingStatus.Idle,
  authorizationsList: {
    currentPage: 0,
    pageSize: 10,
    sortingColumnName: 'authorizationNumber',
    results: [
      {
        memberFirstName: '',
        memberLastName: '',
        authorizationNumber: '',
        requestedProvider: '',
        referringProvider: '',
        requested: '',
        finalized: '',
        status: '',
      },
    ],
    totalResults: 0,
  },
  claimsList: {
    currentPage: 0,
    pageSize: 10,
    sortingColumnName: 'firstName',
    results: [
      {
        claimNumber: '',
        status: '',
        dateReceived: '',
        datePaid: '',
        providerName: '',
        organizationName: '',
        dateOfService: '',
      },
    ],
    totalResults: 0,
  },
  labsList: {
    currentPage: 0,
    pageSize: 10,
    sortingColumnName: 'procedureDate',
    results: [
      {
        id: 0,
        messageControlId: '',
        sendingFacility: '',
        procedureDate: '',
        reportName: '',
      },
    ],
    totalResults: 0,
    sortingDirection: 1,
  },
  claimDetails: [],
  labsDetails: [],
  authorizationDetails: [],
  pathologyList: {
    currentPage: 0,
    pageSize: 10,
    sortingColumnName: 'procedureDate',
    sortingDirection: 'asc',
    results: [
      {
        specialKey: '',
        id: '',
        procedureDate: '',
        referringProvider: '',
        reportType: '',
        pathologist: '',
      },
    ],
    totalResults: 0,
  },
  currentAuthorizationRequestsStep: 1,
  serviceRequestsList: [],
  serviceRequestsListLoading: false,
  diagnosisList: [],
  diagnosisListLoading: false,
  placeOfServiceList: [],
  newAuthorizationRequest: {
    authorizationNumber: '',
    requested: '',
    finalized: '',
    validFrom: '',
    validFromTimeRange: '',
    validTo: '',
    retroDate: '',
    status: '',
    clinicalIndication: '',
    placeOfService: '',
    numberOfVisits: 0,
    urgent: '',
    diagnoses: [
      {
        code: '',
        description: '',
      },
    ],
    services: [
      {
        quantity: 0,
        code: '',
        serviceTypeCode: '',
        description: '',
      },
    ],
    patient: {
      memberID: '',
      healthPlan: '',
      lastName: '',
      firstName: '',
      dateOfBirth: '',
      phone: '',
      pcpLastName: '',
      pcpFirstName: '',
    },
    requestedProvider: {
      fullName: '',
      phone: '',
      fax: '',
      street: '',
      city: '',
      state: '',
      zip: '',
    },
    referringProvider: {
      fullName: '',
      phone: '',
      fax: '',
      street: '',
      city: '',
      state: '',
      zip: '',
    },
  },
  newAuthReqStatus: 'none',
  rxList: {
    currentPage: 0,
    pageSize: 0,
    sortingColumnName: '',
    sortingDirection: 0,
    results: [],
    totalResults: 0,
  },
  transcriptionsList: {
    currentPage: 0,
    pageSize: 10,
    sortingColumnName: 'reportName',
    sortingDirection: 'asc',
    results: [
      {
        id: '',
        messageControlId: '',
        sendingFacility: '',
        procedureDate: '',
        admitDate: '',
        dischargeDate: '',
        reportName: '',
      },
    ],
    totalResults: 0,
  },
  transcriptionsDetails: [],
  radiologyList: {
    currentPage: 0,
    pageSize: 10,
    sortingColumnName: 'procedureDate',
    results: [
      {
        id: '',
        messageControlId: '',
        sendingFacility: '',
        procedureDate: '',
        reportName: '',
      },
    ],
    totalResults: 0,
    sortingDirection: 1,
  },
  radiologyDetails: [],
  issueReportLoading: {
    loadingData: false,
    isDataFetched: false,
  },
  issueReportSubmitted: {
    isReportSubmitted: false,
  },
  authHealPlanType: 0,
  isAuthRetroactive: null,
  isBabylon: { behavioralProgram: '' },
}

export default createReducer(initialState, builder =>
  builder
    .addCase(fetchPatients.pending, state => {
      state.loadingData = true
      state.isDataFetched = false
      state.patientsTreatingRelationship.loadingData = false
      state.patientsTreatingRelationship.isDataFetched = false
    })
    .addCase(fetchPatients.fulfilled, (state, action) => {
      state.loadingData = false
      state.patientsList = action.payload
      state.isDataFetched = true
    })
    .addCase(fetchPatients.rejected, state => {
      state.loadingData = false
      state.isDataFetched = false
    })
    .addCase(fetchPatientById.pending, state => {
      state.loadingData = true
      state.loadingPatientData = LoadingStatus.Pending
      state.isDataFetched = false
    })
    .addCase(fetchPatientById.fulfilled, (state, action) => {
      state.loadingData = false
      state.loadingPatientData = LoadingStatus.Succeeded
      state.currentPatient = action.payload
      state.isDataFetched = true
    })
    .addCase(fetchPatientById.rejected, state => {
      state.loadingData = false
      state.loadingPatientData = LoadingStatus.Failed
      state.isDataFetched = false
    })
    .addCase(fetchPatientTreatingRelationship.pending, state => {
      state.patientsTreatingRelationship.loadingData = true
      state.patientsTreatingRelationship.isDataFetched = false

      state.isDataFetched = false
    })
    .addCase(fetchPatientTreatingRelationship.fulfilled, (state, action) => {
      state.patientsTreatingRelationship.loadingData = false
      state.patientsTreatingRelationship.isDataFetched = true
      state.patientTreatingRelationship = action.payload
    })
    .addCase(fetchPatientTreatingRelationship.rejected, state => {
      state.patientsTreatingRelationship.loadingData = false
      state.patientsTreatingRelationship.isDataFetched = false
    })

    .addCase(setTreatingRelation.pending, state => {
      state.loadingData = true
      state.isDataFetched = false
    })
    .addCase(setTreatingRelation.fulfilled, (state, action) => {
      state.loadingData = false
      state.isDataFetched = true
      state.patientTreatingRelationship = true
    })
    .addCase(setTreatingRelation.rejected, state => {
      state.loadingData = false
      state.isDataFetched = false
    })
    .addCase(fetchPatientServices.pending, state => {
      state.loadingData = true
      state.isDataFetched = false
    })
    .addCase(fetchPatientServices.fulfilled, (state, action) => {
      state.loadingData = false
      state.patientServices = action.payload
      state.isDataFetched = true
    })
    .addCase(fetchPatientServices.rejected, (state, action) => {
      state.loadingData = false
      state.isDataFetched = false
    })
    .addCase(fetchClaimsData.pending, state => {
      state.loadingData = true
      state.isDataFetched = false
    })
    .addCase(fetchClaimsData.fulfilled, (state, action) => {
      state.loadingData = false
      state.claimsList = action.payload
      state.isDataFetched = true
    })
    .addCase(fetchClaimsData.rejected, state => {
      state.loadingData = false
      state.isDataFetched = false
    })
    .addCase(fetchClaimDetails.pending, state => {
      state.claimSubTableLoading.loadingData = true
      state.claimSubTableLoading.isDataFetched = false
    })
    .addCase(fetchClaimDetails.fulfilled, (state, action) => {
      state.claimSubTableLoading.loadingData = false
      state.claimDetails.push(action.payload)
      state.claimSubTableLoading.isDataFetched = true
    })
    .addCase(fetchClaimDetails.rejected, state => {
      state.claimSubTableLoading.loadingData = false
      state.claimSubTableLoading.isDataFetched = false
    })
    .addCase(removeClaimDetails, (state, action) => {
      state.claimDetails = state.claimDetails.filter(
        element => element.claimNumber !== action.payload
      )
    })

    .addCase(fetchPathologyData.pending, state => {
      state.loadingData = true
      state.isDataFetched = false
    })
    .addCase(fetchPathologyData.fulfilled, (state, action) => {
      state.loadingData = false
      state.pathologyList = action.payload
      state.isDataFetched = true
    })
    .addCase(fetchPathologyData.rejected, state => {
      state.loadingData = false
      state.isDataFetched = false
    })

    .addCase(setSelectedPatientId, (state, action) => {
      state.selectedPatientId = action.payload
    })
    .addCase(setSelectedPatientSpecialKey, (state, action) => {
      state.selectedPatientSpecialKey = action.payload
    })
    .addCase(setSelectedPatientData, (state, action) => {
      state.selectedPatientData = action.payload
    })
    .addCase(fetchAuthorizationsData.pending, state => {
      state.loadingData = true
      state.isDataFetched = false
    })
    .addCase(fetchAuthorizationsData.fulfilled, (state, action) => {
      state.loadingData = false
      state.authorizationsList = action.payload
      state.isDataFetched = true
    })
    .addCase(fetchAuthorizationsData.rejected, state => {
      state.loadingData = false
      state.isDataFetched = false
    })

    .addCase(fetchAuthorizationDetails.pending, (state, action) => {
      state.authorizationSubTableLoading = LoadingStatus.Pending
    })
    .addCase(fetchAuthorizationDetails.fulfilled, (state, action) => {
      state.authorizationDetails.push(action.payload)
      state.authorizationSubTableLoading = LoadingStatus.Succeeded
    })
    .addCase(fetchAuthorizationDetails.rejected, (state, action) => {
      state.authorizationSubTableLoading = LoadingStatus.Failed
    })
    .addCase(removeAuthorizationDetails, (state, action) => {
      state.authorizationDetails = state.authorizationDetails.filter(
        element => element.authorizationNumber !== action.payload
      )
    })
    .addCase(setCurrentAuthorizationRequestsStep, (state, action) => {
      state.currentAuthorizationRequestsStep = action.payload
    })
    .addCase(fetchServiceRequestsList.fulfilled, (state, action) => {
      state.serviceRequestsList = action.payload
      state.serviceRequestsListLoading = false
    })
    .addCase(fetchServiceRequestsList.pending, state => {
      state.serviceRequestsListLoading = true
    })
    .addCase(fetchServiceRequestsList.rejected, state => {
      state.serviceRequestsListLoading = false
    })
    .addCase(fetchDiagnosisList.fulfilled, (state, action) => {
      state.diagnosisList = action.payload
      state.diagnosisListLoading = false
    })
    .addCase(fetchDiagnosisList.pending, state => {
      state.diagnosisListLoading = true
    })
    .addCase(fetchDiagnosisList.rejected, state => {
      state.diagnosisListLoading = false
    })
    .addCase(fetchPlaceOfServiceCodes.fulfilled, (state, action) => {
      state.placeOfServiceList = action.payload
    })
    .addCase(createNewAuthorizationRequest.pending, state => {
      state.newAuthReqStatus = 'pending'
    })
    .addCase(createNewAuthorizationRequest.fulfilled, (state, action) => {
      state.newAuthReqStatus = 'fulfilled'
      state.newAuthorizationRequest = action.payload
    })
    .addCase(createNewAuthorizationRequest.rejected, state => {
      state.newAuthReqStatus = 'rejected'
    })
    .addCase(searchRxList.pending, state => {
      state.loadingData = true
      state.isDataFetched = false
    })
    .addCase(searchRxList.fulfilled, (state, action) => {
      state.loadingData = false
      state.rxList = action.payload
      state.isDataFetched = true
    })
    .addCase(searchRxList.rejected, state => {
      state.loadingData = false
      state.isDataFetched = false
    })
    .addCase(fetchTranscriptionsData.pending, state => {
      state.loadingData = true
      state.isDataFetched = false
    })
    .addCase(fetchTranscriptionsData.fulfilled, (state, action) => {
      state.loadingData = false
      state.transcriptionsList = action.payload
      state.isDataFetched = true
    })
    .addCase(fetchTranscriptionsData.rejected, state => {
      state.loadingData = false
      state.isDataFetched = false
    })
    .addCase(fetchTranscriptionsDetails.pending, state => {
      state.transcriptionsSubTableLoading.loadingData = true
      state.transcriptionsSubTableLoading.isDataFetched = false
    })
    .addCase(fetchTranscriptionsDetails.fulfilled, (state, action) => {
      state.transcriptionsSubTableLoading.loadingData = false
      state.transcriptionsDetails.push(action.payload)
      state.transcriptionsSubTableLoading.isDataFetched = true
    })
    .addCase(fetchTranscriptionsDetails.rejected, state => {
      state.transcriptionsSubTableLoading.loadingData = false
      state.transcriptionsSubTableLoading.isDataFetched = false
    })
    .addCase(removeTranscriptionsDetails, (state, action) => {
      state.transcriptionsDetails = state.transcriptionsDetails.filter(
        element => element.id !== action.payload
      )
    })

    .addCase(fetchLabsData.pending, state => {
      state.loadingData = true
      state.isDataFetched = false
    })
    .addCase(fetchLabsData.fulfilled, (state, action) => {
      state.loadingData = false
      state.labsList = action.payload
      state.isDataFetched = true
    })
    .addCase(fetchLabsData.rejected, state => {
      state.loadingData = false
      state.isDataFetched = false
    })
    .addCase(fetchLabsDetails.pending, state => {
      state.labsSubTableLoading.loadingData = true
      state.labsSubTableLoading.isDataFetched = false
    })
    .addCase(fetchLabsDetails.fulfilled, (state, action) => {
      state.labsSubTableLoading.loadingData = false
      state.labsDetails.push(action.payload)
      state.labsSubTableLoading.isDataFetched = true
    })
    .addCase(fetchLabsDetails.rejected, state => {
      state.labsSubTableLoading.loadingData = false
      state.labsSubTableLoading.isDataFetched = false
    })
    .addCase(removeLabsDetails, (state, action) => {
      state.labsDetails = state.labsDetails.filter(
        element => element.messageControlId !== action.payload
      )
    })

    .addCase(fetchRadiologyData.pending, state => {
      state.loadingData = true
      state.isDataFetched = false
    })
    .addCase(fetchRadiologyData.fulfilled, (state, action) => {
      state.loadingData = false
      state.radiologyList = action.payload
      state.isDataFetched = true
    })
    .addCase(fetchRadiologyData.rejected, state => {
      state.loadingData = false
      state.isDataFetched = false
    })
    .addCase(fetchRadiologyDetails.pending, state => {
      state.radiologySubTableLoading.loadingData = true
      state.radiologySubTableLoading.isDataFetched = false
    })
    .addCase(fetchRadiologyDetails.fulfilled, (state, action) => {
      state.radiologySubTableLoading.loadingData = false
      state.radiologyDetails.push(action.payload)
      state.radiologySubTableLoading.isDataFetched = true
    })
    .addCase(fetchRadiologyDetails.rejected, state => {
      state.radiologySubTableLoading.loadingData = false
      state.radiologySubTableLoading.isDataFetched = false
    })
    .addCase(removeRadiologyDetails, (state, action) => {
      state.radiologyDetails = state.radiologyDetails.filter(
        element => element.messageControlId !== action.payload
      )
    })
    .addCase(createIssueReport.fulfilled, state => {
      state.issueReportLoading.loadingData = false
      state.issueReportLoading.isDataFetched = true
    })
    .addCase(createIssueReport.pending, state => {
      state.issueReportLoading.loadingData = true
      state.issueReportLoading.isDataFetched = false
    })
    .addCase(createIssueReport.rejected, state => {
      state.issueReportLoading.loadingData = false
      state.issueReportLoading.isDataFetched = false
    })
    .addCase(reportSubmittedAction, (state, action) => {
      state.issueReportSubmitted.isReportSubmitted = action.payload
      state.issueReportLoading.isDataFetched = false
    })
    .addCase(checkAuthHealthPlanType.fulfilled, (state, action) => {
      state.authHealPlanType = action.payload
    })
    .addCase(setAuthHealthPlanTypeToDefault, state => {
      state.authHealPlanType = 0
    })
    .addCase(setAuthRetroactiveStatus, (state, action) => {
      state.isAuthRetroactive = action.payload
    })
    .addCase(resetBabylonCheck, state => {
      state.isBabylon = { behavioralProgram: '' }
    })

    .addCase(checkIfBabylon.fulfilled, (state, action) => {
      state.isBabylon = action.payload
    })
)
