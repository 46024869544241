import { Api, Auth } from 'shared/services'

import { config, configFCMG } from 'config'
import { MMN } from '../strings'
import { GetHealthPlanReportsListPayload } from '../types/data'

const lineOfBusiness = window.localStorage.getItem('lineOfBusiness')

export default class HealthPlanReportsApi extends Api {
  public appConfig =
    lineOfBusiness === MMN ? config.API_URL : configFCMG.API_URL

  public async getListOfHealthPlanReports(
    payload: GetHealthPlanReportsListPayload
  ) {
    const API = `${this.appConfig}/reports/health-plans`
    const username = Auth.getUsername()
    const {
      departmentType,
      frequency,
      currentPage,
      pageSize,
      sortByColumn,
      sortingDirection,
    } = payload

    const requestConfig = {
      params: {
        UserName: username,
        DepartmentType: departmentType,
        Frequency: frequency,
        CurrentPage: currentPage,
        PageSize: pageSize,
        SortByColumn: sortByColumn,
        SortingDirection: sortingDirection,
      },
    }

    return this.api.get(API, requestConfig).then(response => response.data)
  }
}
