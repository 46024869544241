import colors from 'config/colors'
import { Overrides } from '@material-ui/core/styles/overrides'
import { MuiPickersOverrides } from '@material-ui/pickers/typings/overrides'

type overridesNameToClassKey = {
  [P in keyof MuiPickersOverrides]: keyof MuiPickersOverrides[P]
}

declare module '@material-ui/core/styles/overrides' {
  export interface ComponentNameToClassKey extends overridesNameToClassKey {}
}

const overrides: Overrides = {
  MuiTab: {
    root: {
      '&.Mui-selected': {
        color: colors.font.orange,
      },
    },
  },
  MuiCssBaseline: {
    '@global': {
      body: {
        margin: 0,
        backgroundColor: colors.background.primary,
      },
      '@keyframes fadeIn': {
        from: {
          transform: 'translateY(-30px)',
          opacity: 0,
        },
        to: {
          transform: 'translateY(0)',
          opacity: 1,
        },
      },
      '.Mui-focused.Mui-focused.Mui-focused': {
        borderColor: colors.decoration.primary,
        transition: '.3s',
      },
      '.MuiOutlinedInput-notchedOutline.MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '@page': {
        size: '210mm 297mm',
        /* Chrome sets own margins, we change these printer settings */
        margin: '27mm 16mm 27mm 16mm',
      },
      '@media print': {
        '@page': {
          size: 'A4' /* DIN A4 standard, Europe */,
          margin: 0,
        },
        'html, body': {
          width: '210mm',
          /* height: 297mm; */
          height: '282mm',
          fontSize: 11,
          background: '#FFF',
          overflow: 'visible',
        },
        body: {
          paddingTop: '15mm',
        },
      },
    },
  },

  MuiOutlinedInput: {
    root: {
      backgroundColor: colors.background.primary,
      borderRadius: 15,
      border: '1px solid transparent',
      transition: '.3s',
    },
    input: {
      padding: '18px 27px',
    },
  },

  MuiFilledInput: {
    root: {
      margin: 0,
      padding: 0,
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '&::before': {
        display: 'none',
      },
      '&::after': {
        display: 'none',
      },
    },
    input: {
      display: 'block',
      padding: '17px 27px',
      borderRadius: 15,
      backgroundColor: colors.background.primary,
    },
  },

  MuiInputBase: {
    root: {
      position: 'relative',
    },
    input: {
      letterSpacing: 1,
      fontWeight: 600,
      '&::placeholder': {
        fontWeight: 400,
        color: colors.font.black,
        opacity: 0.6,
      },
    },
  },

  MuiTableSortLabel: {
    root: {
      color: colors.font.black,
      '&>div>svg': {
        fill: '#B2B0B0',
        opacity: 1,
      },
      '&:hover': {
        color: colors.font.black,
      },
    },
    active: {
      '&>div>svg': {
        fill: colors.decoration.darkGrey,
      },
    },
    icon: {
      opacity: 1,
      '&&': {
        margin: 0,
      },
      '&>svg': {
        fill: colors.decoration.darkGrey,
      },
    },
    iconDirectionDesc: {
      '&>svg:last-child': {
        fill: colors.decoration.black,
      },
    },
    iconDirectionAsc: {
      '&>svg:first-child': {
        fill: colors.decoration.black,
      },
    },
  },

  MuiTableCell: {
    alignRight: {
      flexDirection: 'row',
    },
    footer: {
      color: colors.font.black,
      fontWeight: 600,
    },
  },

  MuiTablePagination: {
    selectRoot: {
      border: `1px solid ${colors.decoration.tertiary}`,
      borderRadius: 4,
      overflow: 'hidden',
    },
    toolbar: {
      paddingTop: 25,
    },
  },

  MuiSelect: {
    root: {
      fontWeight: 400,
      fontSize: 13,
    },
  },

  MuiFormHelperText: {
    root: {
      position: 'absolute',
      top: -26,
      right: 0,
    },
  },

  MuiPickersDay: {
    day: {
      color: colors.font.black,
    },
    daySelected: {
      backgroundColor: colors.background.primary,
      border: `2px solid ${colors.button.root}`,
      color: colors.button.root,

      '&:hover': {
        backgroundColor: colors.button.root,
        color: colors.font.white,
      },
    },
    current: {
      color: colors.font.black,
    },
  },
  MuiCheckbox: {
    root: {
      color: colors.button.root,
    },
    colorSecondary: {
      '&&': {
        color: colors.button.root,
      },
    },
  },

  MuiPaper: {
    rounded: {
      borderRadius: 15,
    },
  },
}

export default overrides
