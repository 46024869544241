import { Loadable } from 'shared/components'
import { ModuleRoute } from 'shared/types'
import { addBasePath } from 'shared/utils'
import HomeIcon from '@material-ui/icons/Home'

export const BASE_PATH = '/'

const routes = addBasePath<ModuleRoute>(BASE_PATH, [
  {
    path: '/my-account',
    label: 'navigation.myAccount',
    title: 'My account',
    Icon: HomeIcon,
    exact: true,
    component: Loadable({
      component: () => import('./containers/UserAccount'),
    }),
    navDisplay: false,
  },
])

export default routes
