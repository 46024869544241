import { paths } from 'config'
import { RouteProps } from 'react-router-dom'
import { Loadable } from './components'

const routes: RouteProps[] = [
  {
    path: paths.login,
    exact: true,
    component: Loadable({
      component: () => import('./components/Login'),
    }),
  },
  {
    path: paths.registration,
    exact: true,
    component: Loadable({
      component: () => import('./components/Registration'),
    }),
  },
  {
    path: paths.request_sent_successfully,
    exact: true,
    component: Loadable({
      component: () =>
        import(
          './components/Registration/RegistrationFirstPhase/RequestSentSuccessfullyComponent'
        ),
    }),
  },
  {
    path: paths.request_sent_fail,
    exact: true,
    component: Loadable({
      component: () =>
        import(
          './components/Registration/RegistrationFirstPhase/RequestSentFailedComponent'
        ),
    }),
  },

  {
    path: paths.request_accepted,
    exact: true,
    component: Loadable({
      component: () =>
        import(
          './components/Registration/SupervisorFlow/RequestAcceptedComponent'
        ),
    }),
  },
  {
    path: paths.request_deny,
    exact: true,
    component: Loadable({
      component: () =>
        import('./components/Registration/SupervisorFlow/RequestDenyComponent'),
    }),
  },
  {
    path: paths.request_denied,
    exact: true,
    component: Loadable({
      component: () =>
        import(
          './components/Registration/SupervisorFlow/RequestDeniedComponent'
        ),
    }),
  },
  {
    path: paths.set_password,
    exact: true,
    component: Loadable({
      component: () =>
        import(
          './components/Registration/RegistrationSecondPhase/RegistrationSetPasswordComponent'
        ),
    }),
  },
  {
    path: paths.request_link_expired,
    exact: true,
    component: Loadable({
      component: () => import('./components/Registration/RequestLinkExpired'),
    }),
  },
]

export default routes
