import { makeStyles, styled } from '@material-ui/core'
import { colors } from 'config'

export const LogoWrapper = styled('div')(({ theme }) => ({
  textAlign: 'center',
  marginBottom: '1rem',

  width: '100%',
  '& > img': {
    width: '100%',
    maxWidth: 310,
  },
}))

export const TestEnvComponent = styled('div')({
  color: colors.font.blue,
  fontWeight: 700,
  fontSize: '1.2rem',
})

export const StyledHealthPlanLabel = styled('div')({
  width: '125%',
  background:
    'linear-gradient(90deg, #002B5F 0%, #002B5F 46.41%, #0685BD 103.61%)',
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
  borderRadius: '0px 15px 15px 0px',
  padding: '.75rem 1rem .75rem 2rem',
  fontSize: '17px !important',
  lineHeight: '17px',
  letterSpacing: '0.02em',
  color: colors.font.white,
  margin: '10px -10px 0 -20px',
  fontWeight: 'bold',
  cursor: 'default',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  fontFamily: 'Gotham Light !important',
})

export const StyledHealthPlanLineOfBusiness = styled('div')({
  color: colors.font.lightBlueHover,
  paddingTop: '.5rem',
})

export const StyledFCMGLabel = styled('div')({
  width: '125%',
  background:
    'linear-gradient(90deg, #002B5F 0%, #002B5F 46.41%, #0685BD 103.61%)',
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
  borderRadius: '0px 15px 15px 0px',
  padding: '.75rem .3rem .75rem 2rem',
  fontSize: '17px !important',
  lineHeight: '17px',
  letterSpacing: '0.02em',
  color: colors.font.white,
  margin: '10px 0px 10px -20px',
  fontWeight: 'bold',
  cursor: 'default',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  fontFamily: 'Gotham Light !important',
})

export const useLogoStyles = makeStyles({
  navigationLogo: {
    marginBottom: 0,
    marginTop: 0,
    paddingLeft: 20,
    paddingRight: 20,
    '& > img': {
      display: 'block',
      margin: '0 auto',
    },
    '& > div': {
      fontSize: '0.71rem',
    },
  },
})
