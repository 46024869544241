import { Loadable } from 'shared/components'
import { ModuleRoute } from 'shared/types'
import { addBasePath } from 'shared/utils'
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo'
export const BASE_PATH = '/'

const routes = addBasePath<ModuleRoute>(BASE_PATH, [
  {
    path: '/training-videos',
    label: 'Training Videos',
    title: 'Training Videos',
    Icon: OndemandVideoIcon,
    exact: true,
    component: Loadable({
      component: () => import('./containers/TrainingVideos'),
    }),
  },
])

export default routes
