import { createReducer } from '@reduxjs/toolkit'
import { LoadingStatus } from 'shared/types'
import paths from 'config/paths'
import {
  acceptRegistrationRequest,
  createUser,
  denyRegistrationRequest,
  getCozeva,
  getUserDataForRegistrationRequest,
  login,
  register,
  submitSurvey,
  verifyEmail,
} from './actions'
import { UserRegistrationRequestData } from '../../components/Registration/Registration.utils'

interface State {
  isAuthenticated: boolean
  registrationStatus: LoadingStatus
  accessToken: string
  loading: LoadingStatus
  error?: string | null
  loadingData: boolean
  isDataFetched: boolean
  isSomethingGetsWrong: boolean
  isRegistrationSetPasswordSucceed: boolean
  apiRegistrationError: string
  errorStatus: number | null
  registrationRequestUserData: UserRegistrationRequestData | any
  cozevaLink: {
    url: string
  }
  cozevaLinkFetched: boolean
  surveyLoading: boolean
  surveyFetched: boolean
}

const initialState: State = {
  isAuthenticated: false,
  registrationStatus: LoadingStatus.Idle,
  accessToken: '',
  loading: LoadingStatus.Idle,
  error: null,
  loadingData: false,
  isDataFetched: false,
  isSomethingGetsWrong: false,
  isRegistrationSetPasswordSucceed: false,
  apiRegistrationError: '',
  errorStatus: null,
  registrationRequestUserData: {
    firstName: '',
    lastName: '',
    email: '',
  },
  cozevaLink: { url: '' },
  cozevaLinkFetched: false,
  surveyLoading: false,
  surveyFetched: false,
}

export default createReducer(initialState, builder =>
  builder
    .addCase(login.pending, state => {
      state.loading = LoadingStatus.Pending
      state.error = initialState.error
    })
    .addCase(login.fulfilled, (state, action) => {
      // state.accessToken = action.payload
      state.loading = LoadingStatus.Succeeded
      state.error = null
    })
    .addCase(login.rejected, (state, action) => {
      state.loading = LoadingStatus.Pending
      state.error = action.error.message
    })

    .addCase(register.pending, state => {
      state.loadingData = true
      state.isDataFetched = false
      state.registrationStatus = LoadingStatus.Pending
      state.apiRegistrationError = ''
    })
    .addCase(register.fulfilled, (state, action) => {
      state.loadingData = false
      state.isDataFetched = true
      state.registrationStatus = LoadingStatus.Succeeded
    })
    .addCase(register.rejected, (state, action: any) => {
      state.apiRegistrationError = action.payload.message
      state.errorStatus = action.payload.status
      state.isDataFetched = false
      state.registrationStatus = LoadingStatus.Failed
    })

    .addCase(acceptRegistrationRequest.pending, state => {
      state.loadingData = true
      state.isDataFetched = false
      state.isSomethingGetsWrong = false
    })
    .addCase(acceptRegistrationRequest.fulfilled, (state, action) => {
      state.loadingData = false
      state.isDataFetched = true
      state.isSomethingGetsWrong = false
    })
    .addCase(acceptRegistrationRequest.rejected, (state, action: any) => {
      state.isDataFetched = false
      state.loadingData = false
      state.isSomethingGetsWrong = true
    })

    .addCase(denyRegistrationRequest.pending, state => {
      state.loadingData = true
      state.isDataFetched = false
      state.isSomethingGetsWrong = false
    })
    .addCase(denyRegistrationRequest.fulfilled, (state, action) => {
      state.loadingData = false
      state.isDataFetched = true
      state.isSomethingGetsWrong = false
    })
    .addCase(denyRegistrationRequest.rejected, (state, action: any) => {
      state.isDataFetched = false
      state.loadingData = false
      state.isSomethingGetsWrong = true
    })

    .addCase(verifyEmail.pending, state => {
      state.loadingData = true
      state.isDataFetched = false
      state.isSomethingGetsWrong = false
    })
    .addCase(verifyEmail.fulfilled, (state, action) => {
      state.loadingData = false
      state.isDataFetched = true
      state.isSomethingGetsWrong = false
    })
    .addCase(verifyEmail.rejected, (state, action: any) => {
      state.isDataFetched = false
      state.loadingData = false
      state.isSomethingGetsWrong = true
    })

    .addCase(createUser.pending, state => {
      state.loadingData = true
      state.isDataFetched = false
      state.isSomethingGetsWrong = false
      state.isRegistrationSetPasswordSucceed = false
    })
    .addCase(createUser.fulfilled, (state, action) => {
      state.loadingData = false
      state.isDataFetched = true
      state.isSomethingGetsWrong = false
      state.isRegistrationSetPasswordSucceed = true
    })
    .addCase(createUser.rejected, (state, action: any) => {
      state.isDataFetched = false
      state.loadingData = false
      state.isSomethingGetsWrong = true
      state.isRegistrationSetPasswordSucceed = false
    })

    .addCase(getUserDataForRegistrationRequest.pending, state => {
      state.loadingData = true
      state.isDataFetched = false
      state.isSomethingGetsWrong = false
    })
    .addCase(getUserDataForRegistrationRequest.fulfilled, (state, action) => {
      state.loadingData = false
      state.isDataFetched = true
      state.registrationRequestUserData = action.payload
      state.isSomethingGetsWrong = false
    })
    .addCase(
      getUserDataForRegistrationRequest.rejected,
      (state, action: any) => {
        state.isDataFetched = false
        state.loadingData = false
        state.isSomethingGetsWrong = true
      }
    )
    .addCase(getCozeva.fulfilled, (state, action) => {
      state.cozevaLink = action.payload
      state.cozevaLinkFetched = true
    })
    .addCase(getCozeva.rejected, state => {
      state.cozevaLinkFetched = false
    })
    .addCase(submitSurvey.fulfilled, state => {
      state.surveyLoading = false
      state.surveyFetched = true
    })
    .addCase(submitSurvey.rejected, state => {
      state.surveyLoading = false
      state.surveyFetched = false
    })
    .addCase(submitSurvey.pending, state => {
      state.surveyLoading = true
      state.surveyFetched = false
    })
)
