import { Loadable } from 'shared/components'
import { ModuleRoute } from 'shared/types'
import { addBasePath } from 'shared/utils'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'

export const BASE_PATH = '/'

const routes = addBasePath<ModuleRoute>(BASE_PATH, [
  {
    path: 'health-plan-reports/:tabId',
    label: 'Health Plan Reports',
    title: 'Health Plan Reports',
    Icon: AssignmentTurnedInIcon,
    exact: true,
    component: Loadable({
      component: () => import('./containers/HealthPlanReports'),
    }),
  },
])

export default routes
