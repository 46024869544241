import { config, configFCMG } from 'config'
import {
  RecentAuthFiltersPayload,
  RecentAuthListPayload,
  RecentAuthDetailsPayload,
  RecentAuthListPayloadByHealthPlan,
  RecentHPAuthFiltersPayload,
  RecentAuthFileDownloadPayload,
} from '../types/data'
import { Api } from 'shared/services'
import { MMN } from '../strings'

const lineOfBusiness = window.localStorage.getItem('lineOfBusiness')

class RecentAuthorizationApi extends Api {
  public async searchRecentAuthFilters({
    username,
    ...params
  }: RecentAuthFiltersPayload) {
    const API = `${
      lineOfBusiness === MMN ? config.API_URL : configFCMG.API_URL
    }/RecentAuthorization/filters/${username}`
    const requestConfig = {
      params,
    }

    return this.api.get(API, requestConfig).then(response => {
      return response.data
    })
  }

  public async searchRecentAuthFiltersForHP({
    username,
    ...params
  }: RecentHPAuthFiltersPayload) {
    const API = `${
      lineOfBusiness === MMN ? config.API_URL : configFCMG.API_URL
    }/RecentAuthorization/filters/${username}/hpu`
    const requestConfig = {
      params,
    }

    return this.api.get(API, requestConfig).then(response => {
      return response.data
    })
  }

  public async searchRecentAuthList({
    username,
    ...payload
  }: RecentAuthListPayload) {
    const API = `${
      lineOfBusiness === MMN ? config.API_URL : configFCMG.API_URL
    }/RecentAuthorization/${username}`

    const params = {
      ...payload,
      currentPage: payload.currentPage + 1,
      sortingDirection: payload.sortingDirection === 'asc' ? 0 : 1,
    }

    const requestConfig = {
      params,
    }

    return this.api.get(API, requestConfig).then(response => {
      return response.data
    })
  }

  public async searchRecentAuthHealthPlanList({
    username,
    ...payload
  }: RecentAuthListPayloadByHealthPlan) {
    const API = `${
      lineOfBusiness === MMN ? config.API_URL : configFCMG.API_URL
    }/RecentAuthorization/${username}/hpu`

    const params = {
      ...payload,
      currentPage: payload.currentPage + 1,
      sortingDirection: payload.sortingDirection === 'asc' ? 0 : 1,
    }

    const requestConfig = {
      params,
    }

    return this.api.get(API, requestConfig).then(response => response.data)
  }

  public async searchRecentAuthDetails({
    authorizationNumber,
    securityLevel,
  }: RecentAuthDetailsPayload) {
    const API = `${
      lineOfBusiness === MMN ? config.API_URL : configFCMG.API_URL
    }/RecentAuthorization/details/${authorizationNumber}`

    const params = {
      securityLevel,
    }

    const requestConfig = {
      params,
    }

    return this.api.get(API, requestConfig).then(response => response.data)
  }

  public async fetchRecentAuthFiles({
    authorizationNumber,
    FileName,
  }: RecentAuthFileDownloadPayload) {
    const API = `${
      lineOfBusiness === MMN ? config.API_URL : configFCMG.API_URL
    }/RecentAuthorization/details/${authorizationNumber}/download`

    const requestConfig = {
      responseType: 'blob' as ResponseType,

      params: {
        authorizationNumber,
        FileName,
      },
    }

    return this.api.get(API, requestConfig as any).then(response => response)
  }
}

export default RecentAuthorizationApi
