import { Api, Auth } from 'shared/services'
import {
  AdminRequestPayload,
  AccountRequestListItem,
  AdminUsersRequestPayload,
  EditAdminUserPayload,
  GetAdminUserByUserNamePayload,
  AddUserOrgPayload,
  GetOrgListPayload,
  AddNewUserPayload,
} from '../types/data'
import { config, configFCMG } from 'config'
import { MMN } from '../strings'

const lineOfBusiness = window.localStorage.getItem('lineOfBusiness')

export default class AdminPanelApi extends Api {
  public appConfig =
    lineOfBusiness === MMN ? config.API_URL : configFCMG.API_URL

  public async getListOfAuthorizations(payload: AdminRequestPayload) {
    const API = `${this.appConfig}/admin/account-requests`

    const requestConfig = {
      params: {
        showPast: payload.showPast,
        userName: Auth.getUsername(),
        currentPage: payload.page,
        pageSize: payload.pageSize,
        sortByColumn: payload.orderBy,
        sortingDirection: payload.order === 'asc' ? 0 : 1,
        email: payload.email,
        taxId: payload.taxId,
        firstName: payload.firstName,
        lastName: payload.lastName,
      },
    }

    return this.api.get(API, requestConfig).then(response => response.data)
  }

  public async approveAuthorizationRequest(payload: AccountRequestListItem) {
    const API = `${this.appConfig}/admin/approve-account`

    const requestConfig = {
      modifiedBy: Auth.getUsername(),
      accountRequestId: +payload.id,
    }

    return this.api.put(API, requestConfig).then(response => response.data)
  }

  public async denyAuthorizationRequest(payload: AccountRequestListItem) {
    const API = `${this.appConfig}/admin/deny-account`

    const requestConfig = {
      modifiedBy: Auth.getUsername(),
      accountRequestId: +payload.id,
    }

    return this.api.put(API, requestConfig).then(response => response.data)
  }

  public async getUsersAndRelatedOrganizations(
    payload: AdminUsersRequestPayload
  ) {
    const API = `${this.appConfig}/admin/users`

    const requestConfig = {
      params: {
        userName: Auth.getUsername(),
        currentPage: payload.page,
        pageSize: payload.pageSize,
        sortByColumn: payload.orderBy,
        sortingDirection: payload.order === 'asc' ? 0 : 1,
        email: payload.email,
        taxId: payload.taxId,
        firstName: payload.firstName,
        lastName: payload.lastName,
        securityLevel: payload.securityLevel,
        orgName: payload.orgName,
        supervisorFullName: payload.supervisorFullName,
        includeDeletedAccounts: payload.includeDeletedAccounts,
      },
    }

    return this.api.get(API, requestConfig).then(response => response.data)
  }

  public async getUserByUserName(payload: GetAdminUserByUserNamePayload) {
    const API = `${this.appConfig}/admin/users/${payload.userName}`

    return this.api.get(API).then(response => response.data)
  }

  public async editAdminUser(payload: EditAdminUserPayload) {
    const API = `${this.appConfig}/admin/${payload.username}`

    return this.api
      .put(API, {
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: payload.email,
        phone: payload.phone,
        fax: payload.fax,
        securityLevel: payload.accessLevel,
        active: payload.active,
        cozeva: payload.cozeva,
        modifiedBy: Auth.getUsername(),
        isAdmin: !!payload.isAdmin,
      })
      .then(response => response.data)
  }

  public async getListOfUserOrganizations(payload: GetOrgListPayload) {
    const API = `${this.appConfig}/admin/organizations`

    const requestConfig = {
      params: <any>{
        currentPage: payload.currentPage,
        pageSize: payload.pageSize,
      },
    }

    if (payload.orgName) requestConfig.params.orgName = payload.orgName
    if (payload.orgTaxId) requestConfig.params.taxId = payload.orgTaxId

    return this.api.get(API, requestConfig).then(response => response.data)
  }

  public async addNewOrg(payload: AddUserOrgPayload) {
    const API = `${this.appConfig}/admin/organizations`

    const params = {
      userName: payload.userName,
      taxID: payload.taxID,
    }

    return this.api.post(API, params).then(response => response.data)
  }

  public async deleteUserOrg(payload: AddUserOrgPayload) {
    const API = `${this.appConfig}/admin/organizations`
    const requestConfig = {
      data: {
        userName: payload.userName,
        taxID: payload.taxID,
      },
    }

    return this.api.delete(API, requestConfig).then(response => response.data)
  }

  public async getAdminSupervisors() {
    const API = `${this.appConfig}/admin/supervisors`

    return this.api.get(API).then(response => response.data)
  }

  public async addNewUser(payload: AddNewUserPayload) {
    const API = `${this.appConfig}/admin/users`

    const requestConfig = {
      userName: payload.userName,
      firstName: payload.firstName,
      lastName: payload.lastName,
      email: payload.email,
      phone: payload.phone,
      fax: payload.fax,
      securityLevel: payload.securityLevel,
      healthPlanCodes: payload.healthPlanCode,
      active: payload.active,
      taxIdOrNationalProviderId: payload.taxIdOrNationalProviderId,
      createdBy: Auth.getUsername(),
      isAdmin: !!payload.isAdmin,
    }

    return this.api.post(API, requestConfig).then(response => response.data)
  }

  public async getListOfHealthPlanCodes() {
    const API = `${this.appConfig}/admin/health-plans`

    return this.api.get(API).then(response => response.data)
  }

  public async resendVerificationEmail(userId: number) {
    const API = `${this.appConfig}/admin/verify-email/${userId}`

    return this.api.put(API).then(response => response.data)
  }

  public async importUsers(migrationList: File) {
    const formData = new FormData()
    formData.append('attachment', migrationList)
    const API = `${this.appConfig}/admin/users-migration`

    return this.api.post(API, formData).then(response => response.data)
  }

  public async fetchAdminAnalytics(days: number) {
    const API = `${this.appConfig}/User/survey?days=${days}`

    return this.api.get(API).then(response => response.data)
  }

  public async azureUserSync() {
    const API = `${this.appConfig}/User/sync-users-with-azure/sign-in`
    const requestConfig = {
      params: {
        validation: 'update-last-login-code',
      },
    }

    return this.api.get(API, requestConfig).then(response => response.data)
  }
}
