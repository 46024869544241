import jwtDecode from 'jwt-decode'

const decode = (token: string): any => {
  return jwtDecode(token)
}

const getUserIdFromToken = (token: string): string => {
  return decode(token).oid
}

// eslint-disable-next-line import/no-anonymous-default-export
export default { decode, getUserIdFromToken }
