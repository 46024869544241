import { createReducer } from '@reduxjs/toolkit'
import { LoadingStatus } from 'shared/types'
import {
  fetchRecentAuthFilters,
  fetchRecentAuthList,
  fetchRecentAuthDetails,
  removeRecentAuthorizationDetails,
  fetchRecentAuthHealthPlanList,
} from './actions'
import { RecentAuthFilters, RecentAuthList } from '../types/data'
import { AuthorizationDetails } from 'shared/types/data'

interface State {
  loading: LoadingStatus
  loadingFilters: LoadingStatus
  loadingList: LoadingStatus
  loadingDetails: LoadingStatus
  error?: string | null
  recentAuthFilters: RecentAuthFilters
  recentAuthList: RecentAuthList
  recentAuthDetails: AuthorizationDetails[]
}

const initialState: State = {
  loading: LoadingStatus.Idle,
  loadingFilters: LoadingStatus.Idle,
  loadingList: LoadingStatus.Idle,
  loadingDetails: LoadingStatus.Idle,
  error: null,
  recentAuthFilters: {
    requestedProviders: [],
    referringProviders: [],
  },
  recentAuthList: {
    currentPage: 1,
    pageSize: 10,
    sortingColumnName: '',
    sortingDirection: 0,
    results: [],
    totalResults: 0,
  },
  recentAuthDetails: [],
}

export default createReducer(initialState, builder =>
  builder
    .addCase(fetchRecentAuthFilters.pending, state => {
      state.loadingFilters = LoadingStatus.Pending
      state.error = null
    })
    .addCase(fetchRecentAuthFilters.fulfilled, (state, action) => {
      state.loadingFilters = LoadingStatus.Succeeded
      state.error = null
      state.recentAuthFilters = action.payload
    })
    .addCase(fetchRecentAuthFilters.rejected, (state, action) => {
      state.loadingFilters = LoadingStatus.Failed
      state.error = action.error.message
      state.recentAuthFilters = initialState.recentAuthFilters
    })
    .addCase(fetchRecentAuthList.pending, state => {
      state.loadingList = LoadingStatus.Pending
      state.error = null
    })
    .addCase(fetchRecentAuthList.fulfilled, (state, action) => {
      state.loadingList = LoadingStatus.Succeeded
      state.error = null
      state.recentAuthList = action.payload
    })
    .addCase(fetchRecentAuthList.rejected, (state, action) => {
      state.loadingList = LoadingStatus.Failed
      state.error = action.error.message
      state.recentAuthList = initialState.recentAuthList
    })
    .addCase(fetchRecentAuthHealthPlanList.pending, state => {
      state.loadingList = LoadingStatus.Pending
      state.error = null
    })
    .addCase(fetchRecentAuthHealthPlanList.fulfilled, (state, action) => {
      state.loadingList = LoadingStatus.Succeeded
      state.error = null
      state.recentAuthList = action.payload
    })
    .addCase(fetchRecentAuthHealthPlanList.rejected, (state, action) => {
      state.loadingList = LoadingStatus.Failed
      state.error = action.error.message
      state.recentAuthList = initialState.recentAuthList
    })
    .addCase(fetchRecentAuthDetails.pending, state => {
      state.loadingDetails = LoadingStatus.Pending
      state.error = null
    })
    .addCase(fetchRecentAuthDetails.fulfilled, (state, action) => {
      state.loadingDetails = LoadingStatus.Succeeded
      state.error = null
      state.recentAuthDetails.push(action.payload)
    })
    .addCase(fetchRecentAuthDetails.rejected, (state, action) => {
      state.loadingDetails = LoadingStatus.Failed
      state.error = action.error.message
    })
    .addCase(removeRecentAuthorizationDetails, (state, action) => {
      state.recentAuthDetails = state.recentAuthDetails.filter(
        element => element.authorizationNumber !== action.payload
      )
    })
)
