import { styled } from '@material-ui/core'
import { colors } from 'config'

export const Bar = styled('div')({
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 1000,
  width: '100%',
  height: 20,
  backgroundColor: colors.error,
  color: colors.font.white,
  textAlign: 'center',
  '& + div': {
    marginTop: 20,
  },
})
