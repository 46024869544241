import { styled, Grid, makeStyles, Typography } from '@material-ui/core'
import { colors } from 'config'

export const Banner = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 4,
  minWidth: '60%',
  maxWidth: '60%',
  maxHeight: '50%',
  alignSelf: 'center',
  background: colors.background.gradientSecond,
  borderRadius: '32px',
  alignItems: 'center',
  padding: '4.375rem 4.75rem',

  '@media (max-width:1024px)': {
    padding: '1rem 2.75rem ',
  },
})

export const DownTimeText = styled('span')({
  color: colors.decoration.red,
  fontWeight: 700,
  fontSize: '24px',
  lineHeight: '30px',
  textAlign: 'center',
  margin: '3.75rem 0 2.75rem 0',
  fontFamily: 'Gotham',

  '@media (max-width:1024px)': {
    margin: '1.75rem 1.75rem ',
  },
})

export const UrgentSubmissions = styled('span')({
  fontSize: '20px',
  textAlign: 'center',
  fontWeight: 700,
  marginTop: '2.75rem',
  fontFamily: 'Gotham',

  '@media (max-width:768px)': {
    marginTop: '0.75rem ',
  },
})

export const Wrapper = styled(Grid)({
  display: 'flex',
  height: '100%',
  width: '100%',
  flexDirection: 'column',
  position: 'absolute',
  justifyContent: 'center',
  alignItems: 'stretch',
  padding: '0 0 5rem 0',

  background: colors.background.gradientFirst,
})

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: 35,
  lineHeight: '33px',
  height: 'fit-content',
  textAlign: 'center',

  color: colors.decoration.primary,
  '& em': {
    fontFamily: 'Gotham',
  },
}))

export const useFormStyles = makeStyles({
  maintenanceFooter: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
  },
  maintenanceDescription: {
    fontSize: '20px',
    textAlign: 'center',
    fontWeight: 500,
    fontFamily: 'Gotham Light',
  },
  techSupportText: {
    fontWight: 'bold',
    fontSize: '18px',
    lineHeight: '30px',
    textAlign: 'center',
    fontWeight: 700,
    margin: '4.25rem 0 0.75rem 4.75rem',
  },
  contactHeaders: {
    fontWight: 'bold',
    fontSize: '18px',
    lineHeight: '30px',
    textAlign: 'center',
    fontWeight: 700,
    margin: '0.5rem 0 1.25rem 4.75rem',
  },
  phone: {
    fontWight: 'bold',
    fontSize: '18px',
    lineHeight: '30px',
    textAlign: 'center',
    marginLeft: '5px',
  },
  email: {
    fontWight: 'bold',
    fontSize: '18px',
    lineHeight: '30px',
    textAlign: 'center',
    marginLeft: '5px',
    color: '#1E65BA',
    fontWeight: 700,
  },
})
