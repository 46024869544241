import HomeIcon from '@material-ui/icons/Home'
import { Loadable } from 'shared/components'
import { ModuleRoute } from 'shared/types'
import { addBasePath } from 'shared/utils'

export const BASE_PATH = '/'

const routes = addBasePath<ModuleRoute>(BASE_PATH, [
  {
    path: '/results',
    label: 'navigation.results',
    title: 'Search results',
    exact: true,
    component: Loadable({
      component: () => import('./containers/SearchResults'),
    }),
    navDisplay: false,
  },
  {
    path: 'results/profile',
    label: 'navigation.profile',
    title: "Patient's profile",
    exact: true,
    component: Loadable({
      component: () => import('./containers/PatientProfile'),
    }),
    navDisplay: false,
  },
])

export default routes
