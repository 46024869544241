import { RootState } from 'app'

export const getProvidersFilters = (state: RootState) =>
  state.ProviderDirectory.providersFilters

export const getProvidersList = (state: RootState) =>
  state.ProviderDirectory.providersList

export const getAssociatedProvidersList = (state: RootState) =>
  state.ProviderDirectory.associatedProvidersList

export const getReferringProvidersList = (state: RootState) =>
  state.ProviderDirectory.referringProvidersList

export const getProviderDetailsList = (state: RootState) =>
  state.ProviderDirectory.providerDetailsList

export const getDataLoading = (state: RootState) =>
  state.ProviderDirectory.loadingData

export const getIsDataFetched = (state: RootState) =>
  state.ProviderDirectory.isDataFetched

export const getSubTableLoading = (state: RootState) =>
  state.ProviderDirectory.subTableLoading.loadingData

export const getSubTableIsDataFetched = (state: RootState) =>
  state.ProviderDirectory.subTableLoading.isDataFetched
