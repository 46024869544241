import axios, { AxiosInstance } from 'axios'
import { config, configFCMG } from 'config'
import googleAnalytics from 'config/google-analytics'
import Auth from './Auth'
import Msal from './Msal'
import paths from 'config/paths'
const lineOfBusiness = window.localStorage.getItem('lineOfBusiness')
const MMN = 'Meritage Medical Network'

class Api {
  public api: AxiosInstance

  constructor() {
    this.api = axios.create({
      baseURL: lineOfBusiness === MMN ? config.API_URL : configFCMG.API_URL,
    })
    this.api.interceptors.request.use(
      config => {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${Auth.getToken()}`,
        }

        // Refresh token
        const response = Msal.getInstance().getAllAccounts()

        if (response[0]?.homeAccountId) {
          Msal.getInstance().setActiveAccount(response[0].homeAccountId)
          Auth.setActiveAccount(response[0].homeAccountId)

          Msal.getInstance()
            .getSilentToken()
            .then(({ idToken }) => {
              Auth.setToken(idToken)
            })
            .catch(() => {
              localStorage.clear()
              window.location.replace(paths.login)
            })
        }

        return config
      },
      error => {
        return Promise.reject(error)
      }
    )

    this.api.interceptors.response.use(
      response => response,
      error => {
        if (error.response.status === 401 || error.response.status === 403) {
          googleAnalytics.clearUserId()
          localStorage.clear()
          window.location.replace(paths.login)
        } else {
          return Promise.reject(error)
        }
      }
    )
  }
}

export default Api
