import home from './Home'
import RecentAuthorizations from './RecentAuthorizations'
import ProviderDirectory from './ProviderDirectory'
import PatientAssignments from './PatientAssignments'
import PhysicianResources from './Resources'
import Patients from './Patients'
import UserAccount from './UserAccount'
import AdminPanel from './AdminPanel'
import EOB from './EOB'
import DCE from './DCEPatientRoster'
import TrainingVideos from './TrainingVideos'
import HealthPlanReports from './HeathPlanReports'

export const routes = [
  ...home.routes,
  ...RecentAuthorizations.routes,
  ...ProviderDirectory.routes,
  ...PatientAssignments.routes,
  ...Patients.routes,
  ...UserAccount.routes,
  ...EOB.routes,
  ...DCE.routes,
  ...AdminPanel.routes,
  ...PhysicianResources.routes,
  ...TrainingVideos.routes,
  ...HealthPlanReports.routes,
]

export const reducers = {
  home: home.reducer,
  RecentAuthorizations: RecentAuthorizations.reducer,
  ProviderDirectory: ProviderDirectory.reducer,
  PhysicianResources: PhysicianResources.reducer,
  PatientAssignments: PatientAssignments.reducer,
  Patients: Patients.reducer,
  UserAccount: UserAccount.reducer,
  AdminPanel: AdminPanel.reducer,
  EOB: EOB.reducer,
  DCE: DCE.reducer,
  TrainingVideos: TrainingVideos.reducer,
  HealthPlanReports: HealthPlanReports.reducer,
}
