import { RootState } from 'app'

export const getRecentAuthFilters = (state: RootState) =>
  state.RecentAuthorizations.recentAuthFilters

export const getRecentAuthList = (state: RootState) =>
  state.RecentAuthorizations.recentAuthList

export const getRecentAuthLoadingFiltersStatus = (state: RootState) =>
  state.RecentAuthorizations.loadingFilters

export const getRecentAuthLoadingListStatus = (state: RootState) =>
  state.RecentAuthorizations.loadingList

export const getRecentAuthLoadingDetailsStatus = (state: RootState) =>
  state.RecentAuthorizations.loadingDetails

export const getRecentAuthDetails = (state: RootState) =>
  state.RecentAuthorizations.recentAuthDetails
