import { RootState } from 'app'

export const getRegisterFormStatus = (state: RootState) => state.shared.auth
export const getRegisterLoading = (state: RootState) =>
  state.shared.auth.loadingData
export const getRegisterIsDataFetched = (state: RootState) =>
  state.shared.auth.isDataFetched
export const getRegisterIsSomethingGetsWrong = (state: RootState) =>
  state.shared.auth.isSomethingGetsWrong

export const getIsRegistrationSetPasswordSucceed = (state: RootState) =>
  state.shared.auth.isRegistrationSetPasswordSucceed

export const getRegistrationRequestUserData = (state: RootState) =>
  state.shared.auth.registrationRequestUserData

export const getCozevaLink = (state: RootState) => state.shared.auth.cozevaLink

export const getSurveyLoading = (state: RootState) =>
  state.shared.auth.surveyLoading
export const getSurveyFetched = (state: RootState) =>
  state.shared.auth.surveyFetched
