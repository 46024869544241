import ManageAccountsIcon from '@material-ui/icons/AccountBoxRounded'

import { Loadable } from 'shared/components'
import { ModuleRoute } from 'shared/types'
import { addBasePath } from 'shared/utils'

export const BASE_PATH = '/'

const routes = addBasePath<ModuleRoute>(BASE_PATH, [
  {
    path: '/admin',
    label: 'Admin',
    title: 'Admin Panel',
    Icon: ManageAccountsIcon,
    exact: true,
    component: Loadable({
      component: () => import('./containers/AdminPanel'),
    }),
  },
])

export default routes
