import { createAsyncThunk, createAction } from '@reduxjs/toolkit'
import { MODULE_NAME } from '../strings'
import RecentAuthorizationApi from './services'
import {
  RecentAuthFiltersPayload,
  RecentAuthListPayload,
  RecentAuthDetailsPayload,
  RecentAuthListPayloadByHealthPlan,
  RecentHPAuthFiltersPayload,
  RecentAuthFileDownloadPayload,
} from '../types/data'

const api = new RecentAuthorizationApi()

export const fetchRecentAuthFilters = createAsyncThunk(
  `${MODULE_NAME}/recentAuthFilters`,
  async (payload: RecentAuthFiltersPayload) =>
    api.searchRecentAuthFilters(payload)
)

export const fetchRecentAuthFiltersForHP = createAsyncThunk(
  `${MODULE_NAME}/recentAuthFilters`,
  async (payload: RecentHPAuthFiltersPayload) =>
    api.searchRecentAuthFiltersForHP(payload)
)

export const fetchRecentAuthList = createAsyncThunk(
  `${MODULE_NAME}/recentAuthList`,
  async (payload: RecentAuthListPayload) => api.searchRecentAuthList(payload)
)

export const fetchRecentAuthHealthPlanList = createAsyncThunk(
  `${MODULE_NAME}/recentHealthPlanAuthList`,
  async (payload: RecentAuthListPayloadByHealthPlan) =>
    api.searchRecentAuthHealthPlanList(payload)
)

export const fetchRecentAuthDetails = createAsyncThunk(
  `${MODULE_NAME}/recentAuthDetails`,
  async (payload: RecentAuthDetailsPayload) =>
    api.searchRecentAuthDetails(payload)
)

export const downloadRecentAuthFiles = createAsyncThunk(
  `${MODULE_NAME}/recentAuthFilesDownload`,
  async (payload: RecentAuthFileDownloadPayload) =>
    api.fetchRecentAuthFiles(payload)
)

export const removeRecentAuthorizationDetails = createAction<string>(
  `${MODULE_NAME}/removeRecentAuthorization`
)
