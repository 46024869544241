import { HTMLAttributes } from 'react'
import { CircularProgress } from '@material-ui/core'
import { Container, useLoaderStyles } from './Loader.style'

export interface LoaderProps extends HTMLAttributes<HTMLDivElement> {
  size?: number
  type?: 'page' | 'button'
}

const Loader = ({ size = 100, type, ...props }: LoaderProps) => {
  const classes = useLoaderStyles()

  const typeCointainerClass = () => {
    switch (type) {
      case 'page':
        return classes.pageLoader
      case 'button':
        return classes.buttonLoader
      default:
        return ''
    }
  }

  return (
    <Container {...props} className={typeCointainerClass()}>
      <CircularProgress
        size={size}
        className={type === 'button' ? classes.buttonColor : classes.color}
      />
    </Container>
  )
}

export default Loader
