import {
  Wrapper,
  useFormStyles,
  Title,
  Banner,
  DownTimeText,
  UrgentSubmissions,
} from './Maintenance.style'
import Logo from '../../components/Logo/Logo'
import { useTranslation } from 'react-i18next'

const Maintenance = () => {
  const classes = useFormStyles()
  const { t } = useTranslation()

  return (
    <Wrapper>
      <div style={{ display: 'flex', flex: 1, marginTop: '3rem' }}>
        <Logo isMaintenance={false} />
      </div>
      {/*  */}
      <Banner>
        <Title>{t('maintenancePage.title')}</Title>
        <DownTimeText>{t('maintenancePage.downTime')}</DownTimeText>
        <span className={classes.maintenanceDescription}>
          {t('maintenancePage.downTimeDescription')}
        </span>
        <UrgentSubmissions>
          {t('maintenancePage.urgentSubmission')}
        </UrgentSubmissions>
      </Banner>
      {/*  */}
      <div className={classes.maintenanceFooter}>
        <span className={classes.techSupportText}>
          {t('maintenancePage.technicalSupport')}
        </span>
        <span>
          <span className={classes.contactHeaders}>
            {t('maintenancePage.phoneNumber')}
          </span>
          <span className={classes.phone}>
            {t('maintenancePage.phoneNumberValue')}
          </span>
        </span>
        <span>
          <span className={classes.contactHeaders}>
            {t('maintenancePage.email')}
          </span>
          <span className={classes.email}>
            {t('maintenancePage.emailValue')}
          </span>
        </span>
      </div>
    </Wrapper>
  )
}

export default Maintenance
