import { Loadable } from 'shared/components'
import { ModuleRoute } from 'shared/types'
import { addBasePath } from 'shared/utils'
import LiveHelpIcon from '@material-ui/icons/MenuBook'

export const BASE_PATH = '/'

const routes = addBasePath<ModuleRoute>(BASE_PATH, [
  {
    path: '/resources',
    label: 'navigation.resources',
    title: 'Resources',
    Icon: LiveHelpIcon,
    exact: true,
    component: Loadable({
      component: () => import('./containers/Resources'),
    }),
  },
])

export default routes
