import Assignment from '@material-ui/icons/Assignment'

import { Loadable } from 'shared/components'
import { ModuleRoute } from 'shared/types'
import { addBasePath } from 'shared/utils'

export const BASE_PATH = '/'

const routes = addBasePath<ModuleRoute>(BASE_PATH, [
  {
    path: '/DCE-patient-rosters',
    label: 'DCE Patient Rosters',
    title: 'DCE Patient Rosters',
    Icon: Assignment,
    exact: true,
    component: Loadable({
      component: () => import('./containers/DCE'),
    }),
  },
])

export default routes
