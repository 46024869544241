import { Loadable } from 'shared/components'
import { ModuleRoute } from 'shared/types'
import { addBasePath } from 'shared/utils'
import RecentActorsIcon from '@material-ui/icons/RecentActors'

export const BASE_PATH = '/'

const routes = addBasePath<ModuleRoute>(BASE_PATH, [
  {
    path: '/authorizations',
    label: 'navigation.authorizations',
    title: 'Recent Authorizations',
    Icon: RecentActorsIcon,
    exact: true,
    component: Loadable({
      component: () => import('./containers/RecentAuthorizations'),
    }),
  },
])

export default routes
