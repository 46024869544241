import { config, configFCMG } from 'config'
import {
  ProviderDetailsPayload,
  SearchProvidersForm,
  SearchProvidersReferringForm,
} from '../types/data'
import { Api } from 'shared/services'
import {
  ManageAssociatedProvidersPayload,
  SearchAssociatedProvidersForm,
  SearchReferingProvidersPayload,
} from '../../Patients/components/AuthorizationRequestsTab/ReferringProvider/ReferringProvider.utils'
import Auth from 'shared/services/Auth'
import { MMN } from '../strings'

const lineOfBusiness = window.localStorage.getItem('lineOfBusiness')

class ProviderDirectionApi extends Api {
  public appConfig =
    lineOfBusiness === MMN ? config.API_URL : configFCMG.API_URL

  public async providersFilters() {
    const API = `${this.appConfig}/ProviderDirectory/filters/${
      lineOfBusiness === MMN ? 1 : 2
    }`

    return this.api.get(API).then(response => response.data)
  }

  public getSpecialtiesString(specialties: string[] | undefined) {
    if (specialties?.filter(Boolean).length) {
      const specialtiesCount = specialties.filter(Boolean).length

      if (specialtiesCount > 1)
        return specialties
          ? specialties.join('&specialties=').replaceAll(' ', '+')
          : []
      else if (specialtiesCount === 1)
        return `specialties=${specialties?.join()}`.replace('&', '%26')
    } else return ''
  }

  // Providers directory
  public async searchProviders(payload: SearchProvidersForm) {
    const { currentPage, sortingDirection, specialties } = payload
    const API = `${
      this.appConfig
    }/services/providers?${this.getSpecialtiesString(specialties)}`
    delete payload.specialties
    const requestConfig = {
      params: {
        ...payload,
        currentPage: currentPage + 1,
        sortingDirection: sortingDirection === 'asc' ? 0 : 1,
      },
    }

    return this.api.get(API, requestConfig).then(response => response.data)
  }

  // Referring to
  public async searchProvidersReferring(payload: SearchProvidersReferringForm) {
    const { currentPage, sortingDirection, specialties } = payload
    const API = `${
      this.appConfig
    }/services/providers/patients?${this.getSpecialtiesString(specialties)}`
    delete payload.specialties
    const requestConfig = {
      params: {
        ...payload,
        currentPage: currentPage + 1,
        sortingDirection: sortingDirection === 'asc' ? 0 : 1,
      },
    }

    return this.api.get(API, requestConfig).then(response => response.data)
  }

  public async searchProviderDetails(payload: ProviderDetailsPayload) {
    const API = `${this.appConfig}/ProviderDirectory/${payload.providerID}`
    const requestConfig = {
      params: {
        officeType: payload.officeType,
      },
    }

    return this.api.get(API, requestConfig).then(response => {
      response.data.id = payload.providerID
      return response.data
    })
  }

  public async searchAssociatedProviders(
    payload: SearchAssociatedProvidersForm
  ) {
    const direction = payload.order === 'asc' ? 0 : 1
    const showAssociation =
      payload.userAssociation === 'showEnabled'
        ? 1
        : payload.userAssociation === 'showDisabled'
        ? 0
        : null

    const username = Auth.getUsername()
    const API = `${this.appConfig}/User/manage-providers`
    const requestConfig = {
      params: {
        UserName: username,
        Specialty: payload.specialty,
        City: payload.city,
        ContractType: payload.contractType,
        ShowAssociation: showAssociation,
        CurrentPage: payload.page,
        PageSize: payload.pageSize,
        sortByColumn: payload.orderBy,
        sortingDirection: direction,
        providerFirstName: payload.providerFirstName,
        providerLastName: payload.providerLastName,
      },
    }
    const response = await this.api.get(API, requestConfig)
    return response.data
  }

  public async addAssociatedProvider(
    payload: ManageAssociatedProvidersPayload
  ) {
    const username = Auth.getUsername()
    const API = `${this.appConfig}/User/manage-providers`
    const requestConfig = {
      params: {
        nationalProviderShortId: payload.nationalProviderShortId,
        username,
      },
    }
    return this.api.post(API, requestConfig.params)
  }

  public async removeAssociatedProvider(
    payload: ManageAssociatedProvidersPayload
  ) {
    const username = Auth.getUsername()
    const API = `${this.appConfig}/User/manage-providers`
    const requestConfig = {
      params: {
        nationalProviderShortId: payload.nationalProviderShortId,
        username,
      },
    }

    const response = await this.api.delete(API, requestConfig)
    return response.data
  }

  public async searchReferringProviders(
    payload: SearchReferingProvidersPayload
  ) {
    const API = `${this.appConfig}/services/referring-providers`
    const requestConfig = {
      params: payload,
    }

    const response = await this.api.get(API, requestConfig)
    return response.data
  }
}

export default ProviderDirectionApi
