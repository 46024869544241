import { createAsyncThunk } from '@reduxjs/toolkit'
import { MODULE_NAME } from '../strings'
import { GetHealthPlanReportsListPayload } from '../types/data'
import HealthPlanReportsApi from './services'

const api = new HealthPlanReportsApi()

export const fetchHealthPlanReportsList = createAsyncThunk(
  `${MODULE_NAME}/health-plan-reports-list`,
  async (payload: GetHealthPlanReportsListPayload) =>
    api.getListOfHealthPlanReports(payload)
)
