import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { paths } from 'config'
import googleAnalytics from 'config/google-analytics'
import jwt from 'config/jwt'
import { MODULE_NAME } from 'modules/Patients/strings'
import { Auth, Msal } from 'shared/services'
import { RegistrationPayload } from 'shared/components/RegistrationForm/RegistrationForm.utils'
import AuthApi from './services'
import {
  CreateAccountRequestValues,
  CreateUserPayload,
  UserRegistrationRequestPayload,
  VerifyEmailPayload,
} from '../../components/Registration/Registration.utils'
import { CreateIssueReportPayload } from 'shared/components/Navigation/NavigationForm.utils'
import { SubmitSurveyPayload } from 'shared/types'

const api = new AuthApi()

function parseJwt(token: any) {
  let base64Url = token.split('.')[1]
  let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  let jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}

// export const login = createAsyncThunk<string>(
//   `auth/login`,
//   async (_, { rejectWithValue }) => {
//     try {
//       const response = await Msal.getInstance().loginPopup()
//       Msal.getInstance().setActiveAccount(response.account?.homeAccountId)

//       if (response.account?.homeAccountId) {
//         Auth.setActiveAccount(response.account?.homeAccountId)
//       }
//       const { idToken } = await Msal.getInstance().getSilentToken()

//       Auth.setToken(idToken)
//       if (response.idTokenClaims) {
//         googleAnalytics.setUserId(jwt.getUserIdFromToken(idToken))
//         const parsedIdToken = parseJwt(idToken)
//         const username = parsedIdToken.extension_UserName
//         if (username) Auth.setUsername(username)
//       }
//       window.location.replace(paths.home)
//       return idToken
//     } catch (err) {
//       return rejectWithValue(err.response)
//     }
//   }
// )

export const handleRedirectResponse = async () => {
  try {
    const response = await Msal.getInstance().handleRedirectPromise();
    if (response) {
      Msal.getInstance().setActiveAccount(response.account?.homeAccountId);

      if (response.account?.homeAccountId) {
        Auth.setActiveAccount(response.account?.homeAccountId);
      }
      const { idToken } = await Msal.getInstance().getSilentToken();

      Auth.setToken(idToken);
      if (response.idTokenClaims) {
        googleAnalytics.setUserId(jwt.getUserIdFromToken(idToken));
        const parsedIdToken = parseJwt(idToken);
        const username = parsedIdToken.extension_UserName;
        if (username) Auth.setUsername(username);
      }
      window.location.replace(paths.home);
    }
  } catch (err) {
    console.error('Error handling redirect response', err);
  }
};

export const login = createAsyncThunk<void>(
  `auth/login`,
  async (_, { rejectWithValue }) => {
    try {
      Msal.getInstance().loginRedirect(); // Changed from loginPopup to loginRedirect
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const register = createAsyncThunk(
  `${MODULE_NAME}/registration`,
  async (payload: RegistrationPayload, { rejectWithValue }) => {
    try {
      Auth.setRegistrationEmail(payload.email)
      await api.createAccount(payload)
      window.location.replace(paths.request_sent_successfully)
    } catch (error) {
      if (error.response.status !== 400 && error.response.status !== 404) {
        window.location.replace(paths.request_sent_fail)
      }
      return rejectWithValue({
        message: error.response.data.Message,
        status: error.response.status,
        error,
      })
    }
  }
)

export const logout = createAsyncThunk<string>(
  `auth/logout`,
  async (_, { rejectWithValue }) => {
    try {
      const homeAccountId = Auth.getActiveAccount()
      if (homeAccountId) {
        const logoutRequest = {
          account: Msal.getInstance().getAccountByHomeId(homeAccountId),
        }
        return await Msal.getInstance()
          .logoutPopup(logoutRequest)
          .then(() => {
            googleAnalytics.clearUserId()
            localStorage.clear()
            window.location.replace(paths.login)
          })
      }
    } catch (error) {
      return error
    }
  }
)

export const acceptRegistrationRequest = createAsyncThunk(
  `${MODULE_NAME}/acceptreqistrationrequest`,
  async (payload: CreateAccountRequestValues, { rejectWithValue }) => {
    try {
      await api.acceptRegistrationRequest(payload)
    } catch (error) {
      if (error.response.status === 400) {
        window.location.replace(paths.request_link_expired)
      }
      return rejectWithValue({
        message: error.response.data.Message,
        status: error.response.status,
        error,
      })
    }
  }
)

export const denyRegistrationRequest = createAsyncThunk(
  `${MODULE_NAME}/denyreqistrationrequest`,
  async (payload: CreateAccountRequestValues, { rejectWithValue }) => {
    try {
      await api.denyRegistrationRequest(payload)
      const url = `${paths.request_denied}?lastName=${payload.lastName}&firstName=${payload.firstName}`
      window.location.replace(url)
    } catch (error) {
      if (error.response.status === 400) {
        window.location.replace(paths.request_link_expired)
      }
      return rejectWithValue({
        message: error.response.data.Message,
        status: error.response.status,
        error,
      })
    }
  }
)
export const verifyEmail = createAsyncThunk(
  `${MODULE_NAME}/verifyEmail`,
  async (payload: VerifyEmailPayload, { rejectWithValue }) => {
    try {
      await api.verifyEmail(payload)
    } catch (error) {
      if (error.response.status === 400) {
        window.location.replace(paths.request_link_expired)
      }
      return rejectWithValue({
        message: error.response.data.Message,
        status: error.response.status,
        error,
      })
    }
  }
)
export const createUser = createAsyncThunk(
  `${MODULE_NAME}/createUser`,
  async (payload: CreateUserPayload, { rejectWithValue }) => {
    try {
      await api.createUser(payload)
      window.location.replace(paths.login)
    } catch (error) {
      if (error.response.status === 400) {
        window.location.replace(paths.request_link_expired)
      }
      return rejectWithValue({
        message: error.response.data.Message,
        status: error.response.status,
        error,
      })
    }
  }
)

export const getUserDataForRegistrationRequest = createAsyncThunk(
  `${MODULE_NAME}/getuserdataforregistrationrequest`,
  async (payload: UserRegistrationRequestPayload, { rejectWithValue }) => {
    try {
      return await api.getUserDataForRegistrationRequest(payload)
    } catch (error) {
      if (error.response.status === 400) {
        window.location.replace(paths.request_link_expired)
      }
      return rejectWithValue({
        message: error.response.data.Message,
        status: error.response.status,
        error,
      })
    }
  }
)

export const createIssueReport = createAsyncThunk(
  `${MODULE_NAME}/report`,
  async (payload: CreateIssueReportPayload, { rejectWithValue }) => {
    try {
      await api.createIssueReport(payload)
    } catch (error) {
      return rejectWithValue({
        message: error.response.data.Message,
        status: error.response.status,
        error,
      })
    }
  }
)

export const reportSubmittedAction = createAction<boolean>(
  `${MODULE_NAME}/submitReport`
)

export const getCozeva = createAsyncThunk(
  `${MODULE_NAME}/get-coziva`,
  async () => await api.getCozeva()
)

export const submitSurvey = createAsyncThunk(
  `${MODULE_NAME}/feedback-survey`,
  async (payload: SubmitSurveyPayload) => await api.submitSurvey(payload)
)

export const UpdateIsUserActiveAndLoginCount = createAsyncThunk(
  `${MODULE_NAME}/update-active-status-login-count`,
  async () => await api.updateUserActiveStatus()
)
