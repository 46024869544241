import { createAsyncThunk } from '@reduxjs/toolkit'
import { MODULE_NAME } from '../strings'
import { SearchMembersForm } from '../types/data'
import PatientAssignmentsApi from './services'

const api = new PatientAssignmentsApi()

export const fetchProvidersMembersFilters = createAsyncThunk(
  `${MODULE_NAME}/membersProvidersFiltersData`,
  async () => api.searchProvidersMembersFilters()
)

export const fetchPayorsMembersFilters = createAsyncThunk(
  `${MODULE_NAME}/membersPayorsFiltersData`,
  async () => api.searchPayorsMembersFilters()
)

export const fetchMembersList = createAsyncThunk(
  `${MODULE_NAME}/membersListData`,
  async (payload: SearchMembersForm) => api.searchMembers(payload)
)
