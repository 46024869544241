import { useTestEnvironmentPath } from 'shared/utils/useTestEnvironmentPath'
import logo from 'shared/images/logo.svg'
import {
  LogoWrapper,
  StyledFCMGLabel,
  StyledHealthPlanLabel,
  StyledHealthPlanLineOfBusiness,
  TestEnvComponent,
  useLogoStyles,
} from './Logo.style'
import { Auth } from 'shared/services'
import { MMN, FCMG } from 'modules/Patients/strings'
import { HEALTH_PLAN_ACCESS } from 'shared/strings'

interface LogoProps {
  isNavigation?: boolean
  isMaintenance?: boolean
}

const Logo: React.FC<LogoProps> = ({
  isNavigation = false,
  isMaintenance = false,
}) => {
  const classes = useLogoStyles()
  const userName = Auth.getUsername()
  const isTestEnv = useTestEnvironmentPath()

  const location = window.location.href
  const lineOfBusiness = window.localStorage.getItem('lineOfBusiness')
  const userSecurityLevel = localStorage.getItem('currentAccessLevel')

  let brandLabel

  switch (lineOfBusiness) {
    case MMN:
      switch (userSecurityLevel) {
        case HEALTH_PLAN_ACCESS:
          if (
            userName &&
            !location.includes('login') &&
            !location.includes('set_password') &&
            !isMaintenance
          )
            brandLabel = (
              <StyledFCMGLabel>
                <div>Health Plan View</div>
                <StyledHealthPlanLineOfBusiness>
                  North Bay Area
                </StyledHealthPlanLineOfBusiness>
              </StyledFCMGLabel>
            )
          break

        default:
          if (
            userName &&
            !location.includes('login') &&
            !location.includes('set_password') &&
            !isMaintenance
          )
            brandLabel = (
              <StyledHealthPlanLabel>North Bay Area</StyledHealthPlanLabel>
            )
          break
      }
      break

    case FCMG:
      switch (userSecurityLevel) {
        case HEALTH_PLAN_ACCESS:
          if (
            userName &&
            !location.includes('login') &&
            !location.includes('set_password')
          )
            brandLabel = (
              <StyledFCMGLabel>
                <div>Health Plan View</div>
                <StyledHealthPlanLineOfBusiness>
                  Central Valley
                </StyledHealthPlanLineOfBusiness>
              </StyledFCMGLabel>
            )
          break

        default:
          if (
            userName &&
            !location.includes('login') &&
            !location.includes('set_password') &&
            !isMaintenance
          )
            brandLabel = (
              <StyledHealthPlanLabel>Central Valley</StyledHealthPlanLabel>
            )
          break
      }
      break

    default:
      break
  }

  return (
    <LogoWrapper className={isNavigation ? classes.navigationLogo : ''}>
      <img
        data-cy="login_mmn-logo--image"
        src={logo}
        alt="Meritage Medical Network - logo"
      />
      {/* {isTestEnv && (
        <TestEnvComponent>MERITAGE MEDICAL NETWORK - TEST</TestEnvComponent>
      )} */}
      {brandLabel}
    </LogoWrapper>
  )
}

export default Logo
