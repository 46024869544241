import { ResponseType } from 'axios'
import moment from 'moment'
import {
  SearchPatientsForm,
  SearchClaimsList,
  searchClaimDetails,
  SearchPatientByIdForm,
  SearchPatientServicesForm,
  searchAuthorizationDetails,
  SearchAuthorizationsList,
  SearchServiceRequestsPayload,
  SearchDiagnosisPayload,
  TreatingRelationshipForm,
  SetTreatingRelationshipForm,
  RxListPayload,
  SearchTranscriptionsDetails,
  SearchTranscriptionsList,
  SearchLabsDetails,
  SearchLabs,
  Patient,
  SearchPathologyList,
  SearchPathologyDetails,
  SearchRadiology,
  SearchRadiologyDetails,
  CheckAuthHealthPlanTypePayload,
  CheckBehavioralHealthProviders,
} from '../types/data'
import { Api } from 'shared/services'
import Auth from 'shared/services/Auth'
import { DATE_FORMAT } from 'shared/utils'
import { config, configFCMG } from 'config'
import { FCMG, MMN } from '../strings'

const lineOfBusiness = window.localStorage.getItem('lineOfBusiness')

export default class PatientsApi extends Api {
  public appConfig =
    lineOfBusiness === MMN ? config.API_URL : configFCMG.API_URL

  public async searchPatients(payload: SearchPatientsForm) {
    const currentPage = payload.currentPage + 1
    const direction = payload.sortingDirection === 'asc' ? 0 : 1
    const API = `${this.appConfig}/Patient`

    const requestConfig = {
      params: {
        ...payload,
        currentPage,
        sortingDirection: direction,
        fromSingleDb: lineOfBusiness === FCMG,
      },
    }

    return this.api.get(API, requestConfig).then(response => response.data)
  }

  public async searchClaimsList(payload: SearchClaimsList) {
    const API = `${this.appConfig}/Patient/${payload.patientID}/claims`
    const { currentPage, pageSize, sortByColumn, sortingDirection } = payload
    const direction = sortingDirection === 'asc' ? 0 : 1
    const username = Auth.getUsername()

    const requestConfig = {
      params: {
        userName: username,
        currentPage: currentPage + 1,
        pageSize,
        sortByColumn,
        sortingDirection: direction,
      },
    }

    return this.api.get(API, requestConfig).then(response => {
      return response.data
    })
  }

  public async searchPatientById(payload: SearchPatientByIdForm) {
    const id = payload.id
    const API = `${this.appConfig}/Patient/${id}/eligibility`
    const requestConfig = {
      params: {
        ...payload,
      },
    }

    return this.api.get(API, requestConfig).then(response => response.data)
  }

  public async getPatientTreatingRelationship(
    payload: TreatingRelationshipForm
  ) {
    const username = Auth.getUsername()
    const API = `${this.appConfig}/Patient/treating-relationship`
    const requestConfig = {
      params: {
        userName: username,
        ...payload,
      },
    }

    return this.api.get(API, requestConfig).then(response => {
      return response.data
    })
  }

  public async setTreatingRelationForPatient(
    payload: SetTreatingRelationshipForm
  ) {
    const username = Auth.getUsername()
    const response = Number(payload.responseOption)
    const API = `${this.appConfig}/Patient/treating-relationship`

    const requestBody = {
      userName: username,
      response: response,
      responseIfOther: payload.responseIfOther,
    }

    const requestConfig = {
      params: {
        specialKey: payload.specialKey,
      },
    }
    return this.api.post(API, requestBody, requestConfig)
  }

  public async searchClaimDetails(payload: searchClaimDetails) {
    const API = `${this.appConfig}/Patient/${payload.patientID}/claims/${payload.claimID}`
    const username = Auth.getUsername()
    const requestConfig = {
      params: { userName: username, ...payload },
    }

    return this.api.get(API, requestConfig).then(response => {
      return response.data
    })
  }

  public async searchAuthorizationDetails(payload: searchAuthorizationDetails) {
    const API = `${this.appConfig}/Patient/authorizations/${payload.authorizationID}`
    const requestConfig = {
      params: {
        ...payload,
        securityLevel: payload.securityLevel,
      },
    }
    return this.api.get(API, requestConfig).then(response => response.data)
  }

  public async searchAuthorizationsList(payload: SearchAuthorizationsList) {
    const API = `${this.appConfig}/Patient/${payload.patientID}/${payload.patientHealthPlanCode}/authorizations`
    const { currentPage, pageSize, sortByColumn, sortingDirection } = payload

    const direction = sortingDirection === 'asc' ? 0 : 1

    const requestConfig = {
      params: {
        currentPage: currentPage + 1,
        pageSize,
        sortByColumn,
        sortingDirection: direction,
      },
    }

    return this.api.get(API, requestConfig).then(response => response.data)
  }

  public async searchPathologyList(payload: SearchPathologyList) {
    const API = `${this.appConfig}/${payload.specialKey}/pathology`
    const {
      specialKey,
      currentPage,
      pageSize,
      sortByColumn,
      sortingDirection,
    } = payload

    const direction = sortingDirection === 'asc' ? 0 : 1

    const requestConfig = {
      params: {
        specialKey,
        currentPage: currentPage + 1,
        pageSize,
        sortByColumn,
        sortingDirection: direction,
      },
    }

    return this.api.get(API, requestConfig).then(response => {
      return response.data
    })
  }

  public async searchPathologyDetails(payload: SearchPathologyDetails) {
    const API = `${this.appConfig}/pathology/${payload.id}`
    const requestConfig = {
      responseType: 'blob' as ResponseType,

      params: {
        id: payload.id,
      },
    }

    this.api.interceptors.response.use(
      response => response,
      error => {
        if (error.response.status === 404) {
          return error.response.status
        } else {
          return Promise.reject(error)
        }
      }
    )

    return this.api.get(API, requestConfig)
  }

  public async searchPatientServices(payload: SearchPatientServicesForm) {
    const patientId = payload.id
    const API = `${this.appConfig}/Patient/${patientId}/copay`

    const { sortingColumnName, sortingDirection } = payload
    const direction = sortingDirection === 'asc' ? 0 : 1

    const requestConfig = {
      params: {
        SortByColumn: sortingColumnName,
        sortingDirection: direction,
      },
    }

    return this.api.get(API, requestConfig).then(response => {
      return response.data
    })
  }

  public async searchServiceRequestsList(
    payload: SearchServiceRequestsPayload
  ) {
    const API = `${this.appConfig}/Services/services`

    const requestConfig = {
      params: {
        ...payload,
      },
    }

    return this.api.get(API, requestConfig).then(response => {
      return response.data
    })
  }

  public async searchDiagnosisList(payload: SearchDiagnosisPayload) {
    const API = `${this.appConfig}/Services/diagnoses`

    const requestConfig = {
      params: {
        ...payload,
      },
    }

    return this.api.get(API, requestConfig).then(response => {
      return response.data
    })
  }

  public async fetchPlaceOfServiceList() {
    const API = `${this.appConfig}/placeofservicecodes`

    return this.api.get(API).then(response => {
      return response.data
    })
  }

  public async createNewAuthorizationRequest(payload: any) {
    const {
      diagnosisCodes,
      serviceRequestCodesAndQuantites,
      placeOfService,
      isUrgent,
      additionalNotes,
      supportingDocumentation,
      formOfDocumentation,
      isSupportingDocumentationAndPCPVisitMandatory,
      referringProvider,
      requestedProvider,
      patient,
      user,
      isRetro,
      retroAuthDate,
    } = payload

    const API = `${this.appConfig}/Patient/${patient.id}/AuthorizationRequests`

    const requestConfig = {
      headers: { 'Content-Type': 'multipart/form-data' },
      params: { patientId: patient.id },
    }

    const formData = new FormData()

    diagnosisCodes.map((diagnose: string) =>
      formData.append('diagnosisCodes', diagnose)
    )
    serviceRequestCodesAndQuantites.forEach((service: any, index: number) => {
      formData.append(
        `ServiceRequestCodesAndQuantites[${index}].Code`,
        service.code
      )
      formData.append(
        `ServiceRequestCodesAndQuantites[${index}].Quantity`,
        service.quantity
      )
    })
    formData.append('PlaceOfService', placeOfService)
    formData.append('IsUrgent', isUrgent)
    formData.append('AdditionalNotes', additionalNotes)
    formData.append('FormOfDocumentation', formOfDocumentation)
    supportingDocumentation.forEach((file: File, index: number) => {
      formData.append(`SupportingDocumentation`, file)
    })
    formData.append(
      'IsSupportingDocumentationAndPCPVisitMandatory',
      isSupportingDocumentationAndPCPVisitMandatory
    )
    formData.append('ReferringProvider.Id', referringProvider.id)
    formData.append(
      'ReferringProvider.AddressSequence',
      referringProvider.addressSequence
    )
    formData.append('RequestedProvider.Id', requestedProvider.id)
    formData.append(
      'RequestedProvider.AddressSequence',
      requestedProvider.addressSequence
    )
    formData.append('Patient.PhoneNumber', patient.phoneNumber)
    formData.append('User.Username', user.username)
    formData.append('User.PhoneNumber', user.phoneNumber)
    formData.append('User.FaxNumber', user.faxNumber)
    formData.append('Company', lineOfBusiness === MMN ? '1' : '2')
    isRetro && formData.append('IsRetro', isRetro)
    retroAuthDate && formData.append('RetroDate', retroAuthDate)

    return this.api.post(API, formData, requestConfig).then(res => res.data)
  }

  public async searchLabsData(payload: SearchLabs) {
    const API = `${this.appConfig}/${payload.specialKey}/lab`
    const direction = payload.sortingDirection === 'asc' ? 0 : 1

    const requestConfig = {
      params: {
        specialKey: payload.specialKey,
        CurrentPage: payload.currentPage + 1,
        PageSize: payload.pageSize,
        SortByColumn: payload.sortByColumn,
        SortingDirection: direction,
      },
    }

    return this.api.get(API, requestConfig).then(response => {
      return response.data
    })
  }

  public modifyDetailsTabHtml(htmlStr: string, patient: Patient) {
    if (
      patient &&
      patient.lastName &&
      patient.firstName &&
      patient.dateOfBirth
    ) {
      const newHtmlData =
        '<b>Patient: ' +
        patient.lastName.toUpperCase() +
        ', ' +
        patient.firstName.toUpperCase() +
        '<br></b>' +
        '<b>DOB:     ' +
        moment(patient.dateOfBirth).format(DATE_FORMAT) +
        '<br></b>'
      return newHtmlData + htmlStr + newHtmlData
    }
  }

  public async searchLabsDetails(payload: SearchLabsDetails) {
    const API = `${this.appConfig}/lab/${payload.messageControlId}`
    const { messageControlId } = payload
    const requestConfig = {
      params: {
        messageControlId,
      },
    }

    return this.api.get(API, requestConfig).then(response => {
      if (!response.data.messageControlId) {
        response.data.messageControlId = payload.messageControlId
      }

      response.data.outputHTML = this.modifyDetailsTabHtml(
        response.data.outputHTML,
        payload.patient
      )
      return response.data
    })
  }

  public async searchRxList(payload: RxListPayload) {
    const API = `${this.appConfig}/${payload.specialKey}/rx`

    const { pageSize, sortByColumn, sortingDirection, currentPage } = payload
    const direction = sortingDirection === 'asc' ? 0 : 1

    const requestConfig = {
      params: {
        CurrentPage: currentPage + 1,
        PageSize: pageSize,
        SortByColumn: sortByColumn,
        SortingDirection: direction,
      },
    }

    return this.api.get(API, requestConfig).then(response => response.data)
  }

  public async searchTranscriptionsList(payload: SearchTranscriptionsList) {
    const API = `${this.appConfig}/${payload.specialKey}/transcription`
    const {
      specialKey,
      currentPage,
      pageSize,
      sortByColumn,
      sortingDirection,
    } = payload
    const direction = sortingDirection === 'asc' ? 0 : 1

    const requestConfig = {
      params: {
        specialKey: specialKey,
        currentPage: currentPage + 1,
        pageSize,
        sortByColumn,
        sortingDirection: direction,
      },
    }

    return this.api.get(API, requestConfig).then(response => {
      return response.data
    })
  }

  public async searchTranscriptionsDetails(
    payload: SearchTranscriptionsDetails
  ) {
    const API = `${this.appConfig}/Transcription/${payload.id}`

    let requestConfig = {}
    if (payload.messageControlId) {
      //to receive html file
      requestConfig = {
        params: {
          transcriptionId: payload.id,
          messageControlId: payload.messageControlId,
        },
      }
    } else {
      //to receive pdf file
      requestConfig = {
        responseType: 'blob' as ResponseType,

        params: {
          transcriptionId: payload.id,
        },
      }
    }

    this.api.interceptors.response.use(
      response => response,
      error => {
        if (error.response.status === 404) {
          return error.response.status
        } else {
          return Promise.reject(error)
        }
      }
    )

    return this.api.get(API, requestConfig).then(response => {
      if (!response.data.id) {
        response.data.id = payload.id
      }

      if (response.data.outputHTML && payload.patient) {
        response.data.outputHTML = this.modifyDetailsTabHtml(
          response.data.outputHTML,
          payload.patient
        )
        return response.data
      }

      const blob = new Blob([response.data], {
        type: 'application/pdf',
      })
      const objectUrl = window.URL.createObjectURL(blob)

      return { url: `${objectUrl}`, id: payload.id }
    })
  }

  public async searchRadiologyData(payload: SearchRadiology) {
    const API = `${this.appConfig}/${payload.specialKey}/radiology`
    const direction = payload.sortingDirection === 'asc' ? 0 : 1

    const requestConfig = {
      params: {
        specialKey: payload.specialKey,
        CurrentPage: payload.currentPage + 1,
        PageSize: payload.pageSize,
        SortByColumn: payload.sortByColumn,
        SortingDirection: direction,
      },
    }
    return this.api.get(API, requestConfig).then(response => {
      return response.data
    })
  }

  public async searchRadiologyDetails(payload: SearchRadiologyDetails) {
    const API = `${this.appConfig}/radiology/${payload.messageControlId}`
    const username = Auth.getUsername()

    const requestConfig = {
      params: {
        userName: username,
        messageControlId: payload.messageControlId,
      },
    }

    return this.api.get(API, requestConfig).then(response => {
      if (!response.data.messageControlId) {
        response.data.messageControlId = payload.messageControlId
      }
      response.data.outputHTML = this.modifyDetailsTabHtml(
        response.data.outputHTML,
        payload.patient
      )
      return response.data
    })
  }

  public async checkAuthHealthPlanType(
    payload: CheckAuthHealthPlanTypePayload
  ) {
    const API = `${this.appConfig}/Patient/${payload.patientId}/AuthorizationRequests/hp-type`
    const requestConfig = {
      params: {
        patientId: payload.patientId,
        company: payload.company,
      },
    }

    return this.api.get(API, requestConfig).then(response => response.data)
  }

  public async isAuthRequiredForCpt(payload: {
    code: string
    company: number
    referringProvider: string | undefined
    referringToProvider: string | undefined
  }) {
    const { code, company, referringProvider, referringToProvider } = payload
    const API = `${
      this.appConfig
    }/Services/cpt/validation?Code=${code}&Company=${company}&referringProvider=${
      referringProvider ? referringProvider : ''
    }&ReferringToProvider=${referringToProvider ? referringToProvider : ''}`

    return this.api.get(API).then(response => response)
  }

  public async checkBehavioralHealthProviders(
    payload: CheckBehavioralHealthProviders
  ) {
    const API = `${this.appConfig}/Patient/authorizations/bbpr`
    const requestConfig = {
      params: {
        ...payload,
      },
    }

    return this.api.get(API, requestConfig).then(response => response.data)
  }

  public async checkIfBabylon(payload: {
    code: string
    addressSequence: number
  }) {
    const { code, addressSequence } = payload
    const API = `${this.appConfig}/Services/services/behavioral-program?id=${code}&AddressSequence=${addressSequence}`

    return this.api.get(API).then(response => response.data)
  }
}
