export const ADMIN_ACCESS = '1'
export const REGULAR_ACCESS = '2'
export const SPECIAL_ACCESS = '3' // we have no info on this access level. need to rename once we do
export const HEALTH_PLAN_ACCESS = '6'
export const MMN = 'Meritage Medical Network'
export const FCMG = 'First Choice Medical Group'
export const NBA = 'North Bay Area'
export const CV = 'Central Valley formerly FCMG'

export const INPATIENT_HOSPITAL_CODE = '21'
export const SKILLED_NURSING_FACILITY_CODE = '31'
export const COMPREHENSIVE_INPATIENT_REHAB_FACILITY_CODE = '61'
