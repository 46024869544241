export type Order = 'asc' | 'desc'

export const PhoneNumberMask = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]

export const validEmail = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
)

export interface Authorization {
  memberFirstName: string
  memberLastName: string
  authorizationNumber: string
  requestedProvider: string
  referringProvider: string
  requested: string
  finalized: string
  status: string
}

export interface AuthorizationsList {
  currentPage: number
  pageSize: number
  sortingColumnName: string
  results: Authorization[]
  totalResults: number
}

export interface AuthorizationsProvider {
  fullName: string
  phone: string
  fax: string
  street: string
  city: string
  state: string
  zip: string
}

export interface Diagnose {
  code: string
  description: string
}

export interface Service {
  quantity: number
  code: string
  serviceTypeCode?: string
  description: string
}

export interface PatientAuth {
  memberID: string
  healthPlan: string
  lastName: string
  firstName: string
  dateOfBirth: string
  phone: string
  pcpLastName: string
  pcpFirstName: string
}

export interface AuthorizationDetails {
  authorizationNumber: string
  requested: string
  finalized: string
  validFrom: string
  validFromTimeRange: string
  validTo: string
  retroDate: string
  status: string
  diagnoses: Diagnose[]
  clinicalIndication: string
  notes?: string
  placeOfService: string
  numberOfVisits: number
  urgent: string
  services: Service[]
  patient: PatientAuth
  requestedProvider: AuthorizationsProvider
  referringProvider: AuthorizationsProvider
  admitTime?: string
  dischargeTime?: string
  placeOfServiceCode?: string
  comments?: {
    comments: string
    createdBy: string
    modified: string
  }
  attachedDocuments?: [
    {
      originalFileName: string
      fileName: string
    }
  ]
}

export interface SubmitSurveyPayload {
  experience?: number
  easyToUse?: number
  expectations?: number
  easyToFindInfo?: number
  proposals?: string
  isFeedbackGiven?: boolean
}
